import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";

import './css/HotelBooking.css'
import ProfileSection from "./SecondNavbar";

const CarBookings = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const [bookings, setBookings] = useState([]);

    const HotelBookingAll = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}car_booking_syt/vendorBookedCar`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setBookings(data.data);
        console.log(data);

    };

    useEffect(() => {
        HotelBookingAll();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    // const [statusFilter, setStatusFilter] = useState('');

    // const handleStatusChange = (event) => {
    //     setStatusFilter(event.target.value);
    // };

    // const filteredBookings = bookings && bookings.filter(booking =>
    //     statusFilter === '' || booking.status === statusFilter
    // );


    //   const HotelBookingAll = async () => {
    //     const token = localStorage.getItem("vendorToken");
    //     const res = await fetch(`${BASE_URL}hotel_booking_syt/all`, {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: token,
    //       },
    //     });
    //     const data = await res.json();
    //     console.log(data);

    //   };

    //   useEffect(() => {
    //     HotelBookingAll();
    //   }, []);

    //   const handleNav = () => {
    //     navigate("/vendor/myprofile");
    //   };


    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
                    <ProfileSection title="Car Bookings" />
                    <section className="new-vendor-container">
                        {/* <div className="filter-container">
                            <div className="filter-title">Booking Status :</div>
                            <select onChange={handleStatusChange} value={statusFilter}>
                                <option value="">All</option>
                                <option value="pending">Pending</option>
                                <option value="reject">Rejected</option>
                                <option value="approve">Approved</option>
                                <option value="cancel">Cancelled</option>
                            </select>
                        </div> */}
                        {bookings && <table className="booking-table">
                            <thead className="booking-table__thead">
                                <tr className="booking-table__tr">
                                    <th className="booking-table__th">Car Details</th>
                                    <th className="booking-table__th">Pickup Address</th>
                                    <th className="booking-table__th">Drop Address</th>
                                    <th className="booking-table__th">One Way/ Two Way</th>
                                    <th className="booking-table__th">Pickup Date, Time</th>
                                    <th className="booking-table__th">Customer Details</th>
                                    <th className="booking-table__th">Actions</th>

                                </tr>
                            </thead>
                            <tbody className="booking-table__tbody">
                                {bookings.map((booking) => (
                                    <tr key={booking._id} className="booking-table__tr">
                                        <td className="booking-table__td">
                                            {booking.vendor_car_details.length > 0 ? (
                                                <>
                                                    <div className="booking-table__hotel-name">{booking.vendor_car_details[0].car_details[0].car_name}</div>
                                                    {/* <div className="booking-table__hotel-id">{booking.hotel_id}</div> */}
                                                    <div className="booking-table__hotel-address">{booking.vendor_car_details[0].car_details[0].fuel_type}</div>
                                                </>
                                            ) : (
                                                <div className="booking-table__no-hotel-details">No Hotel Details</div>
                                            )}
                                        </td>
                                        <td className="booking-table__td">{booking.pickup_address}</td>
                                        <td className="booking-table__td">{booking.drop_address}</td>
                                        <td className="booking-table__td">{booking.twoway ? "Two Way" : "One Way"}</td>
                                        <td className="booking-table__td">{booking.pickup_date.slice(0, 10) + ", " + booking.pickup_time}</td>
                                        <td className="booking-table__td">{booking.name + ", " + booking.mobile_number}</td>
                                        <td className="booking-table__td">
                                            <button className="booking-table__button" onClick={() => navigate(`/vendor/carbookings/${booking._id}`)}>View More</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                        {
                            !bookings && <div>No Bookings</div>
                        }
                    </section>

                </div>
            </div >
        </>
    );
};

export default CarBookings;
