import React, { useEffect, useRef, useState } from "react";
import Hearer from "./Header";
import "../Project/css/index1.css";
import "../Project/css/custom_package.css";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Carousel from 'react-bootstrap/Carousel';
import {
    faChevronDown,
    faHotel,
    faHandHoldingDroplet,
    faHandsHoldingCircle,
    faCalendar,
    faUserDoctor,
    faTaxi,
    faPlane,
    faTrainSubway,
    faPaperclip,
    faBrush,
    faCheck,
    faXmark,
    faXmarkCircle,
    faHandshakeAlt,
    faStar,
    faUsers,
    faArrowRightLong,
    faRupee,
    faIndianRupee,
    faUser,
    faDoorClosed,
    faBowlFood,
    faCookie,
    faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons"
import {
    faCircleCheck,
    faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import loader from "../images/loading.png"
import { Modal, Row } from "react-bootstrap";
import { BASE_URL } from "../BASE_URL";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import countries from "../CountryStateCity.json";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const rightDate = (dateString) => {
    // Check if dateString is a valid string and matches the yyyy-mm-dd format
    if (typeof dateString === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    }
    // If not valid, return the original value
    return dateString;
};

const rightDateToSend = (dateString) => {
    const [day, month, year] = dateString?.split('/');
    return `${year}-${month}-${day}`;
};

const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

function BookingHotel() {
    const { merchentId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const {
        pricePerNight,
        finalAmountWithMargin,
        finalAmountAdultWithMargin,
        finalAmountChildWithMargin,
        finalAmountAdult,
        finalAmountChild,
        totalChild,
        totalAdult,
        totalRooms,
        checkIn,
        checkOut,
        roomId,
        fullname,
        email,
        number,
        // gender,
        state,
        city,
    } = location.state || {};

    console.log(pricePerNight)

    const [hotelDetail, setHotelDetail] = useState("")
    const [roomDetail, setRoomDetail] = useState("")

    const hotelList = async () => {
        const token = localStorage.getItem("userToken");
        const res = await fetch(`${BASE_URL}hotel_syt/details?_id=${id}`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();

        setHotelDetail(data?.data?.[0]);

        const selectedRoom = data?.data?.[0]?.rooms?.find((e) => e?._id === roomId)

        setRoomDetail(selectedRoom)
        // const myId = localStorage.getItem('my_id')
    };

    useEffect(() => {
        hotelList();
    }, [roomId]);

    const [cities, setCities] = useState([])
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')

    const selectedStates = countries?.find((e) => e.name === "India")

    const handleStateChange = (e) => {
        const selected = e.target.value
        setMainState(selected)
        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === selected)
        setCities(selectedCity?.cities)
    }

    useEffect(() => {
        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === state)
        setCities(selectedCity?.cities)
    }, [state]);

    const { id } = useParams();

    const [loading, setLoading] = useState(false)
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleOpen = () => setShow(true)

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const handleStopLoading = () => {
        setLoading(false)
    }

    const overRef = useRef(null)
    const itenarary = useRef(null)
    const services = useRef(null)
    const hotels = useRef(null)
    const privacy = useRef(null)

    const HEADER_OFFSET = 80; // Adjust this value according to your header's height

    const scrollTo = (section) => {
        let targetRef;

        switch (section) {
            case "overview":
                targetRef = overRef;
                break;
            case "services":
                targetRef = services;
                break;
            case "itenarary":
                targetRef = itenarary;
                break;
            case "hotels":
                targetRef = hotels;
                break;
            case "privacy":
                targetRef = privacy;
                break;
            default:
                targetRef = null;
        }

        if (targetRef) {
            window.scroll({
                top: targetRef.current.offsetTop - HEADER_OFFSET, // Subtract header height
                behavior: "smooth",
            });

            // Optionally, you can add a margin after scrolling
            setTimeout(() => {
                if (targetRef.current) {
                    targetRef.current.style.marginTop = "20px"; // Adjust the margin as needed
                }
            }, 500);
        }
    };


    const [bidDetails, setBidDetails] = useState("")
    const [packageDetails, setPackageDetails] = useState("")
    const [adminMarginPercentage, setAdminMarginPercentage] = useState("")

    const [policies, setPolicies] = useState([])

    const privacypolicies = async () => {
        const res = await fetch(`${BASE_URL}policy`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json()
        setPolicies(data?.data)
    }

    useEffect(() => {
        privacypolicies();
    }, []);

    const firstWord = packageDetails?.travel_by?.split(',')[0];

    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [paymentType, setPaymentType] = useState("Full Payment")

    const handlePaymentTypeSelect = (e) => {
        const selectedValue = e.target.value
        setPaymentType(e.target.value)
        localStorage.setItem("paymentType", selectedValue)
    }

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('Male');
    const [travellers, setTravellers] = useState([]);

    const [mainUserName, setMainUserName] = useState(fullname)
    const [mainEmail, setMainEmail] = useState(email)
    const [mainNumber, setMainNumber] = useState(number)
    const [mainState, setMainState] = useState(state)
    const [mainCity, setMainCity] = useState(city)

    const [isChecked, setIsChecked] = useState(false)

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked)
    }

    const handleMakeAPayment = async () => {

        const totalPerson = Number(bidDetails?.total_adult) + Number(bidDetails?.total_child) + Number(bidDetails?.Infant)

        if (!mainUserName) {
            toast.error("Please fill in the Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!mainEmail) {
            toast.error("Please fill in the Email field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        } else if (!emailRegex.test(mainEmail)) {
            toast.error("Please enter a valid Email address!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const numberRegex = /^\d{10}$/;
        if (!mainNumber) {
            toast.error("Please fill in the Number field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        } else if (!numberRegex.test(mainNumber)) {
            toast.error("Number must be exactly 10 digits!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!mainState) {
            toast.error("Please select a State!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!mainCity) {
            toast.error("Please select a City!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        
        if (!isChecked) {
            toast.error("Please Check Our Terms & Conditions!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        setLoading(true);

        const token = localStorage.getItem("userToken")
        const myId = localStorage.getItem("mySytUserId")
        const url = location.pathname;
        const amountToPaid = localStorage.getItem("finalPrice");

        try {

            const res = await fetch(`${BASE_URL}payment/pay`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({
                    MERCHANT_USER_ID: myId,
                    user_id: myId,
                    link: url,
                    amount: 1,
                    paid_amount: finalAmountWithMargin,
                    price_per_person_adult: finalAmountAdult,
                    price_per_person_child: finalAmountChild,
                    hotel_id: id,
                    room_id: roomId,
                    total_booked_rooms: totalRooms,
                    check_in_date: checkIn,
                    check_out_date: checkOut,
                    total_adult: totalAdult,
                    total_child: totalChild,
                    payment_type: "UPI",
                    room_title: roomDetail?.room_title,
                    user_name: mainUserName,
                    gender: gender,
                    country: "India",
                    state: mainState,
                    city: mainCity,
                    // dob: dob,
                    travel_details: travellers,
                    contact_no: mainNumber,
                }),
            });

            const data = await res.json();

            if (!data?.success) {
                setLoading(false);
                toast.error(data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                });
            } else {
                localStorage.setItem('bookingId', data.data.booking_id)
                if (data?.data?.url) {
                    window.location.href = data?.data?.url
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            toast.error("Number must be exactly 10 digits!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const InsertBooking = async () => {
        const token = localStorage.getItem("userToken");
        const bookingId = localStorage.getItem("bookingId");
        const type = localStorage.getItem("paymentType");
        const paidAmount = localStorage.getItem("paidAmount");

        localStorage.removeItem("paymentType");

        setLoading(true);

        const res = await fetch(`${BASE_URL}payment/updateHotelBooking/${bookingId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                status: "booked",
                transaction_id: merchentId
            }),
        });

        const data = await res.json();

        if (data?.success) {
            setLoading(false);
            toast.success("Your Booking Successfully Done!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            setTimeout(() => {
                navigate("/my_package")
            }, 2000);
        } else {
            setLoading(false);
            toast.error(data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
        }
    };

    useEffect(() => {
        if (merchentId !== undefined && merchentId !== "") {
            InsertBooking();
        } else {

        }
    }, [merchentId]);


    const [editIndex, setEditIndex] = useState(null);

    const handleEdit = (index) => {
        const selectedTraveller = travellers[index];
        setFirstName(selectedTraveller.first_name);
        setLastName(selectedTraveller.last_name);
        setDateOfBirth(selectedTraveller.dob);
        setGender(selectedTraveller.gender);
        setEditIndex(index);
        setShow(true);
    };

    const handleSubmit = () => {

        const calculateAge = (dob) => {
            const birthDate = new Date(dob);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        };

        // If updating an existing traveller, exclude them from the count
        const travellersExcludingCurrent = editIndex !== null
            ? travellers.filter((_, index) => index !== editIndex)
            : travellers;

        // Count the number of adults, children, and infants already added (excluding the current traveller being updated)
        const adultCount = travellersExcludingCurrent.filter(traveller => calculateAge(traveller.dob) >= 18).length;
        const childCount = travellersExcludingCurrent.filter(traveller => calculateAge(traveller.dob) >= 2 && calculateAge(traveller.dob) < 18).length;

        // Determine the age of the traveller to be added or updated
        const travellerAge = calculateAge(dateOfBirth);

        // Check if the limits for each group have been reached
        if (travellerAge >= 12 && adultCount >= totalAdult) {
            toast.error(`You can only add a maximum of ${totalAdult} adults!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (travellerAge >= 2 && travellerAge < 12 && childCount >= totalChild) {
            toast.error(`You can only add a maximum of ${totalChild} children!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        // Proceed with the usual validations
        if (!firstName) {
            toast.error("Please fill in the First Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!lastName) {
            toast.error("Please fill in the Last Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!dateOfBirth) {
            toast.error("Please fill in the Date of Birth field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!gender) {
            toast.error("Please select a Gender!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        // Create a new traveller object
        const newTraveller = {
            first_name: firstName,
            last_name: lastName,
            dob: dateOfBirth,
            gender: gender,
        };

        // Add or update the traveller
        if (editIndex !== null) {
            const updatedTravellers = [...travellers];
            updatedTravellers[editIndex] = newTraveller;
            setTravellers(updatedTravellers);
            setEditIndex(null);
        } else {
            setTravellers([...travellers, newTraveller]);
        }


        // Clear input fields
        setFirstName('');
        setLastName('');
        setDateOfBirth('');
        setGender('Male');

        const total = Number(totalChild) + Number(totalAdult);

        if (editIndex !== null) {
            if (Number(total) === travellers?.length) {
                handleClose();
                setEditIndex(null);
            }
        } else {
            if (Number(total) === travellers?.length + 1) {
                handleClose();
            }
        }
    };

    const [persons, setPersons] = useState(1);



    const gotPercentageValue = (baseValue, percentage) => {
        return Number(baseValue) * (Number(percentage) / 100);
    }

    const totalInfant = packageDetails?.Infant || 0;
    const pricePerPersonAdult = packageDetails?.price_per_person_adult || 0;
    const pricePerPersonChild = packageDetails?.price_per_person_child || 0;
    const pricePerPersonInfant = packageDetails?.price_per_person_infant || 0;
    const adminMargin = Number(adminMarginPercentage) || 0;

    // Calculating total number of travellers
    const total_traveller = totalAdult + totalChild + totalInfant;

    // Calculating admin prices per traveller type
    const adminAdult = pricePerPersonAdult + (pricePerPersonAdult * (adminMargin / 100));
    const adminChild = pricePerPersonChild + (pricePerPersonChild * (adminMargin / 100));
    const adminInfant = pricePerPersonInfant + (pricePerPersonInfant * (adminMargin / 100));

    // Calculating grand total
    const grandTotal = (totalAdult * adminAdult) + (totalChild * adminChild) + (totalInfant * adminInfant);


    const [finalPrice, setFinalPrice] = useState(null)
    const [divide, setDivide] = useState(null)
    const [checkBoxValue, setCheckBoxValue] = useState("50")

    const handleChange = (event) => {
        const item = event.target.value;
        setCheckBoxValue(event.target.value)
        setDivide(gotPercentageValue(grandTotal, item))
        const amount = gotPercentageValue(grandTotal, item)
        localStorage.setItem("finalPrice", amount)
    };

    // const handleChange = (event) => {
    //     const item = event.target.value;
    //     setCheckBoxValue(event.target.value)
    //     setFinalPrice(gotPercentageValue(bidDetails?.total_amount, item))
    //     const amount = gotPercentageValue(bidDetails?.total_amount, item)
    //     localStorage.setItem("finalPrice", amount)
    // };

    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const tomorrow = new Date();
    tomorrow.setDate(currentDate.getDate() + 1);
    const formattedTomorrow = tomorrow.toISOString().split("T")[0];
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const formattedYesterday = yesterday.toISOString().split("T")[0];

    const handleDelete = (index) => {
        const updatedTravellers = travellers.filter((_, i) => i !== index);
        setTravellers(updatedTravellers); // assuming you have a state setter for travellers
    };

    const totalStars = 5;

    const [totalDays, setTotalDays] = useState(null)
    const [totalNights, setTotalNights] = useState(null)

    const calculateDaysAndNights = (startDate, endDate) => {
        // Convert the input dates to Date objects
        const start = new Date(checkIn);
        const end = new Date(checkOut);

        const checkInDate = new Date(checkIn);
        const checkOutDate = new Date(checkOut);

        const timeDifference = checkOutDate - checkInDate;
        const numberOfNights = Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));

        // Calculate the difference in days (inclusive of both dates)
        const totalDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;

        // Nights is the same as days in this scenario
        const totalNights = totalDays;

        setTotalDays(totalDays)
        setTotalNights(numberOfNights)

    }

    useEffect(() => {
        if (checkIn && checkOut) {
            calculateDaysAndNights(checkIn, checkOut)
        }
    }, [checkIn, checkOut]);

    return (
        <>
            <Hearer />
            <ToastContainer />

            <div className="">
                <div className="CuStom_package_image">
                    <div className="packagebooking-box">
                        <ToastContainer />
                        <div className="booking-header">
                            <div className="booking-header-content container">
                                <div>
                                    <h4 className="mb-1 text22">
                                        {roomDetail?.room_title} <span className="mb-0">({totalRooms} Rooms)</span>
                                    </h4>
                                    <p className="booking-header-travelling-dates mb-0 text14">{rightDate(checkIn)} <span className="mx-2"><FontAwesomeIcon icon={faArrowRightLong} /></span> <span>{totalNights}N</span> <span className="mx-2"><FontAwesomeIcon icon={faArrowRightLong} /></span> {rightDate(checkOut)}</p>
                                </div>
                                <div className="booking-header-pricing">
                                    <p className="mb-0 mt-1 sm-mt-0 text20 font800">
                                        <FontAwesomeIcon icon={faIndianRupee} className="me-1" />
                                        {finalAmountAdultWithMargin}/person
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="packagebooking-grid container mt-4">
                            <div className="packagebooking-tabs">
                                <div className="booking-accordion-item mb-1">
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(1)}>
                                        <h5 className="mb-0 text18">1.Traveller Details</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 1 ? 'active' : ''}`}>
                                        {activeAccordion === 1 &&
                                            <>
                                                <div className="booking-traveller-box">
                                                    <div className="booking-traveller-box-header">
                                                        <div>
                                                            <h5 className="mb-0 text15">1.TOTAL TRAVELLER</h5>
                                                        </div>
                                                        <div className="d-flex gap-2">
                                                            <span className="text12">
                                                                {totalAdult} Adults
                                                                {totalChild > 0 && `, ${totalChild} Children`}
                                                            </span>

                                                        </div>
                                                        {travellers?.length !== (Number(totalAdult) + Number(totalChild)) && (
                                                            <div className="ms-auto">
                                                                <h6 onClick={handleOpen}>+ Add Travellers Details</h6>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="booking-traveller-box-body mx-2 mt-4">
                                                        {travellers && travellers?.map((e, index) => (
                                                            <div className="booking-traveller-box-traveller-list" key={index}>
                                                                <div className="text13">
                                                                    <b>{index + 1}.{e?.first_name} {e?.last_name}</b>
                                                                </div>
                                                                <div className="text13">
                                                                    <b>{e?.gender}</b>
                                                                </div>
                                                                <div className="text13">
                                                                    <b>
                                                                        {rightDate(e?.dob)}({calculateAge(e?.dob)})
                                                                    </b>
                                                                </div>
                                                                <div className="text13">
                                                                    <b>
                                                                        {Number(calculateAge(e?.dob)) > 12
                                                                            ? 'Adult'
                                                                            : Number(calculateAge(e?.dob)) >= 2 && Number(calculateAge(e?.dob)) <= 12
                                                                                ? 'Children'
                                                                                : Number(calculateAge(e?.dob)) < 2
                                                                                    ? 'Infant'
                                                                                    : ""}
                                                                    </b>
                                                                </div>
                                                                <div className="text13 d-flex">
                                                                    <p className="mb-0 me-3" onClick={() => handleEdit(index)}>UPDATE</p>
                                                                    <p className="mb-0 text-danger" onClick={() => handleDelete(index)}>DELETE</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <hr />
                                                    <div className="booking-traveller-form my-4">
                                                        <div>
                                                            <h5 className="text15">2.PLEASE ENETER CONTACT DETAILS <span className="text-red">*</span></h5>
                                                        </div>
                                                        <div className="booking-traveller-form-details">
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="" className="font600">Name <span className="text-red">*</span></label>
                                                                <input type="text" value={mainUserName} onChange={(e) => setMainUserName(e.target.value)} />
                                                            </div>
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="" className="font600">Email <span className="text-red">*</span></label>
                                                                <input type="email" value={mainEmail} onChange={(e) => setMainEmail(e.target.value)} />
                                                            </div>
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="" className="font600">Mobile Number <span className="text-red">*</span></label>
                                                                <input type="tel" value={mainNumber} onChange={(e) => setMainNumber(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="booking-traveller-form mb-3">
                                                        <div>
                                                            <h5 className="text15">3.PLEASE ENTER GST DETAILS <span className="text-red">*</span></h5>
                                                        </div>
                                                        <div className="booking-traveller-form-details">
                                                            <div className="">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <p htmlFor="" className="text14 font600">Address</p>
                                                                    <textarea name="" id="" className="w-100" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <p htmlFor="" className="text14 font600">Gst State <span className="text-red">*</span></p>
                                                                    <select name="" id="" className="w-100" value={mainState} onChange={handleStateChange}>
                                                                        <option value="">select state</option>
                                                                        {selectedStates && selectedStates?.states.map((e) => (
                                                                            <option value={e?.name}>{e?.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <p htmlFor="" className="text14 font600">City</p>
                                                                    <select name="" id="" className="w-100" value={mainCity} onChange={(e) => setSelectedCity(e?.target?.value)}>
                                                                        <option value="">select city</option>
                                                                        {cities && cities?.map((e) => (
                                                                            <option value={e?.name}>{e?.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="booking-accordion-item mb-1">
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(2)}>
                                        <h5 className="mb-0 text18">2.Hotel Details</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 2 ? 'active' : ''}`}>
                                        {activeAccordion === 2 &&
                                            <>
                                                <div class="row gx-3" >
                                                    <div class="col-xl-12 col-lg-8 col-md-12 col-sm-12">
                                                        <div class="rose-valley" >
                                                            <div>
                                                                <div>
                                                                    <div class="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
                                                                        <div class="me-4">
                                                                            <h4 class="m-0 text-start font700 text18">{hotelDetail?.hotel_name}</h4>
                                                                        </div>
                                                                        <div class="d-flex">
                                                                            {[...Array(totalStars)].map((_, index) => {
                                                                                return (
                                                                                    <FontAwesomeIcon
                                                                                        key={index}
                                                                                        icon={faStar}
                                                                                        style={{ color: "yellow" }}
                                                                                    />
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p class="mt-2 pb-3 border-bot text14">
                                                                    {hotelDetail?.hotel_address},{hotelDetail?.city},{hotelDetail?.state}
                                                                </p>
                                                            </div>
                                                            <div class="rose-paragraph">
                                                                <p className="font500">{hotelDetail.hotel_description}</p>
                                                            </div>
                                                            <div>
                                                                <div className="font700 text16">Other Details</div>
                                                                <ul className="mt-2">
                                                                    {hotelDetail.other?.map(e => (
                                                                        <li className="text14">{e}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div class="highlights my-4">
                                                            <div class="me-4 mb-4">
                                                                <h4 class="m-0 text18 font700">Highlights</h4>
                                                            </div>
                                                            <div class="row text-center">
                                                                {hotelDetail?.Highlights && hotelDetail?.Highlights.map((e) => (
                                                                    <div className="col-2">
                                                                        <img src={e.icon} alt="" class="mb-2" style={{ height: "40px", width: "40px" }} />
                                                                        <p className="font600">{e.title}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className="delux-room" >
                                                            <div >
                                                                <div className="" style={{ border: "1px solid black", padding: "10px", borderRadius: "20px" }}>
                                                                    <div>
                                                                        <Carousel style={{ height: "200px", width: "100%" }}>
                                                                            {roomDetail?.photos.map((e, index) => (
                                                                                <Carousel.Item key={index} interval={2000} >
                                                                                    <img src={e} alt={`Slide ${index}`} className="inner-rooms-image w-100" />
                                                                                </Carousel.Item>
                                                                            ))}
                                                                        </Carousel>
                                                                    </div>
                                                                    <div >
                                                                        <div className="facilities">
                                                                            <h5 className="text16 font700">
                                                                                {roomDetail?.room_title}
                                                                                <span className={`badge ms-2 ${roomDetail?.status === 'available' ? 'bg-success' : ''} ${roomDetail?.status === 'sold out' ? 'bg-danger' : ''}`}>
                                                                                    {roomDetail?.status}
                                                                                </span>
                                                                            </h5>
                                                                            <div>
                                                                                <ul className="mb-1 row">
                                                                                    {roomDetail?.room_highlights.map((elem) => (
                                                                                        <li className="col-7" key={elem}>{elem}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                            <div>
                                                                                <h2 className="mb-xl-3 mb-lg-3 mb-md-2 mb-sm-1 mb-1 text20 font700">
                                                                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                                                    {finalAmountWithMargin}<span></span>
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* amenities  */}

                                                        {/* <div class="amenities my-4">
                                                            <h5 class="ps-2 text18 font700">Amenities and facilities</h5>
                                                            <div class="row px-4">
                                                                {hotelDetail?.amenities && hotelDetail?.amenities.map((e) => (
                                                                    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 antiviral">
                                                                        <h6 className="font600">{e.title}</h6>
                                                                        <ul class="mb-1">
                                                                            {e?.points.map((data) => (
                                                                                <li class="mb-1">{data}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div> */}


                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="booking-accordion-item mb-2" ref={privacy}>
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(3)}>
                                        <h5 className="mb-0 text18">3.Terms & Conditions</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 3 ? 'active' : ''}`}>
                                        {activeAccordion === 3 &&
                                            <>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[2]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[0]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[1]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="packagebooking-payment-card">
                                {/* <div className="packagebooking-payment-type-box">
                                    <p className="mb-0">Payment Type</p>
                                    <select value={paymentType} onChange={handlePaymentTypeSelect} name="" id="">
                                        <option value="">select</option>
                                        <option value="Full Payment">Full Payment</option>
                                        <option value="Partial Payment">Partial Payment</option>
                                    </select>
                                </div> */}

                                {paymentType === "Partial Payment" && (
                                    <div className="partial-payment-box mt-3 d-flex justify-content-evenly">
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="partial_payment"
                                                checked={Number(checkBoxValue) === 30}
                                                value="30"
                                                onChange={handleChange}
                                            />
                                            <p className="mb-0 ms-2">30%</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="partial_payment"
                                                checked={Number(checkBoxValue) === 50}
                                                value="50"
                                                onChange={handleChange}
                                            />
                                            <p className="mb-0 ms-2">50%</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="partial_payment"
                                                checked={Number(checkBoxValue) === 100}
                                                value="100"
                                                onChange={handleChange}
                                            />
                                            <p className="mb-0 ms-2">100%</p>
                                        </div>
                                    </div>
                                )}

                                <div className="packagebooking-payment-card-body my-3">
                                    <div>
                                        <p className="mb-0">Adults</p>
                                        <p className="mb-0">{totalAdult}
                                            {/* * {finalAmountAdultWithMargin} = {totalAdult * finalAmountAdultWithMargin}  */}
                                            <span></span></p>
                                    </div>
                                    {totalChild !== 0 && (
                                        <div>
                                            <p className="mb-0">Children</p>
                                            <p className="mb-0">{totalChild}
                                                {/* * {finalAmountChildWithMargin} = {totalChild * finalAmountChildWithMargin}  */}
                                                <span></span></p>
                                        </div>
                                    )}

                                    <div>
                                        <p className="mb-0">Total Rooms</p>
                                        <p className="mb-0">{totalRooms} <span></span></p>
                                    </div>

                                    <div>
                                        <p className="mb-0">Total Night</p>
                                        <p className="mb-0">{totalNights} <span></span></p>
                                    </div>

                                    <div>
                                        <p className="mb-0">Price Per Night</p>
                                        <p className="mb-0">₹{pricePerNight} <span></span></p>
                                    </div>

                                    <hr className="my-1" />

                                    <div>
                                        <p className="mb-0">Total Amount</p>
                                        <p className="mb-0">₹{Number(totalRooms) * Number(pricePerNight) * Number(totalNights)}<span></span></p>
                                    </div>
                                    <div>
                                        <p className="mb-0">GST</p>
                                        <p className="mb-0">
                                            ₹0
                                        </p>
                                    </div>
                                    <div>
                                        <p className="mb-0">Other Tax</p>
                                        <p className="mb-0">
                                            ₹0
                                        </p>
                                    </div>

                                </div>
                                <div className="packagebooking-payment-card-header">
                                    <span className="mb-0">Grand Total</span>
                                    <h5 className="mb-0">₹{Number(totalRooms) * Number(pricePerNight) * Number(totalNights)}</h5>
                                </div>
                                <div className='text-center text14 mt-3 d-flex justify-content-center'>
                                    <div>
                                        <div className='d-flex align-items-top'>
                                            <span>
                                                <input
                                                    type='checkbox'
                                                    onChange={handleCheckboxChange}
                                                />
                                            </span>
                                            <p className='cmnp text-start rgba ps-1'>
                                                I agree to{" "}
                                                <a
                                                    href='/privacy-policy'
                                                    target="_blank"
                                                    style={{ color: "#09646d" }}
                                                >
                                                    Privacy Policy
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                    href='/payment-policy'
                                                    target="_blank"
                                                    style={{ color: "#09646d" }}
                                                >
                                                    Terms of services
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="packagebooking-payment-card-footer mt-3">
                                    {loading ? (
                                        <img onClick={handleStopLoading} src={loader} alt="" className="spinner" />
                                    ) : (
                                        <button onClick={handleMakeAPayment}>Make Payment</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Body className="pt-3 px-5">
                    <div className="traveller-information-add-modal">
                        <div className="traveller-information-add-modal-header">
                            <h5>Add Traveller Details</h5>
                        </div>
                        <div>
                            <div className="mt-4">
                                <h5 className="mb-0">Basic Informations <span style={{ fontSize: "12px" }}>({travellers?.length + 1} Traveller)</span></h5>
                                <hr />
                            </div>
                            <div className="traveller-detail-form">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">First Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter a First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value?.trim())}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Last Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter a Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value?.trim())}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Date Of Birth</label>
                                    <input
                                        type="date"
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        max={formattedYesterday}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label className="mb-2">Gender</label>
                                    <div className="d-flex gap-5">
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="gender"
                                                value="Male"
                                                className="me-2"
                                                style={{ marginTop: "1px" }}
                                                checked={gender === "Male"}
                                                onChange={(e) => setGender(e.target.value)}
                                            />
                                            <label className="me-2">
                                                Male
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="gender"
                                                className="me-2"
                                                style={{ marginTop: "1px" }}
                                                value="Female"
                                                checked={gender === "Female"}
                                                onChange={(e) => setGender(e.target.value)}
                                            />
                                            <label>
                                                Female
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-4">
                        <button
                            className="reject-modal reject-modal-no"
                            onClick={handleClose}
                        >
                            No
                        </button>
                        <button
                            className="reject-modal reject-modal-yes ms-2"
                            onClick={handleSubmit}
                        >
                            Yes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <hr />
            <Footer />
        </>
    );
}

export default BookingHotel;
