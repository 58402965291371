import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";
import { Rating } from "@mui/material";
import Button from "react-bootstrap/Button";
import './css/Hotels.css'
import ProfileSection from "./SecondNavbar";
import Carousel from 'react-bootstrap/Carousel';
import countries from "../CountryStateCity.json";
import StarRating from "../Project/StarRating";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

const MyHotels = () => {

    const states = countries.find((e) => e.name === "India")?.states || [];

    const navigate = useNavigate();

    const handleViewHotel = (hotel) => {
        navigate(`/vendor/hotel-details/${hotel._id}`, {
            state: { source: "edit" }
        });
    };

    const handleAddHotels = () => {
        navigate(`/vendor/myhotels/Addhotels`)
    }

    const getStarRatingFromHotelType = (hotelType) => {
        const hotelTypeToStars = {
            "5 star": 5,
            "4 star": 4,
            "3 star": 3,
            "2 star": 2,
            "1 star": 1,
        };
        return hotelTypeToStars[hotelType.toLowerCase()] || 0;
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [cities, setCities] = useState([]);
    const [selectedStar, setSelectedStar] = useState("");

    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        const filteredCities = states.find((s) => s.name === state)?.cities || [];
        setCities(filteredCities);
        setSelectedCity("");
    };

    // Handle Star Filter Change
    const handleStarChange = (e) => {
        setSelectedStar(e.target.value);
    };

    const [hotels, setHotels] = useState([]);

    const filteredHotels = hotels.filter((hotel) => {
        return (
            hotel.hotel_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedState ? hotel.hotel_state === selectedState : true) &&
            (selectedCity ? hotel.hotel_city === selectedCity : true) &&
            (selectedStar ? hotel.hotel_type === Number(selectedStar) : true)
        );
    });

    const fetchHotels = async () => {
        try {
            const token = localStorage.getItem("vendorToken");
            const response = await fetch(`${BASE_URL}hotel_syt/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }); // Replace with your actual API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            setHotels(data.data);

        } catch (error) {
            // setError(error.message);
        }
    };

    useEffect(() => {
        fetchHotels();
    }, []);

    const [isRotating, setIsRotating] = useState(false);

    const handleClearFilters = () => {
        setIsRotating(true);
        setTimeout(() => setIsRotating(false), 500);
        setSearchTerm("");
        setSelectedState("");
        setSelectedCity("");
        setCities([]);
        setSelectedStar("");
    };


    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ProfileSection title="My Hotels" />
                <div style={{ backgroundColor: "white", minHeight: "100vh" }}>

                    <section className="new-vendor-container">
                        {/* <div style={{ display: "flex", justifyContent: "end" }}>
                            <button className="check-availibility-button text-20 border-0 mb-4" onClick={handleAddHotels}>
                                Add Hotels
                            </button>
                        </div> */}

                        <div className="row">
                            {/* Hotel Name Search */}
                            <div className="col-3 mb-3">
                                <p className="mb-1 font600 text14 hotels-filter-label-css">Search Hotel Name</p>
                                <input
                                    type="text"
                                    className="hotels-filter-input-css"
                                    placeholder="Search by hotel name..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            {/* State Filter */}
                            <div className="col-2 mb-3">
                                <p className="mb-1 font600 text14 hotels-filter-label-css">State</p>
                                <select
                                    className="hotels-filter-input-css"
                                    onChange={handleStateChange}
                                    value={selectedState}
                                >
                                    <option value="">Select State</option>
                                    {states.map((state, index) => (
                                        <option key={index} value={state.name}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* City Filter */}
                            <div className="col-2 mb-3">
                                <p className="mb-1 font600 text14 hotels-filter-label-css">City</p>
                                <select
                                    className="hotels-filter-input-css"
                                    onChange={(e) => setSelectedCity(e.target.value)}
                                    value={selectedCity}
                                >
                                    <option value="">Select City</option>
                                    {cities.map((city, index) => (
                                        <option key={index} value={city.name}>
                                            {city.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Star Filter */}
                            <div className="col-2 mb-3">
                                <p className="mb-1 font600 text14 hotels-filter-label-css">Hotel Type</p>
                                <select
                                    className="hotels-filter-input-css"
                                    onChange={handleStarChange}
                                    value={selectedStar}
                                >
                                    <option value="">Select Hotel Type</option>
                                    <option value="1">1 Star</option>
                                    <option value="2">2 Star</option>
                                    <option value="3">3 Star</option>
                                    <option value="4">4 Star</option>
                                    <option value="5">5 Star</option>
                                </select>
                            </div>
                            <div className="col-1 mb-3">
                                <div className="d-flex align-items-end h-100">
                                    <button
                                        className={`reset-button-css text-20 border-0 px-3`}
                                        onClick={handleClearFilters}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} className={`${isRotating ? "rotate-icon" : ""}`} size="lg" title="Clear Filters" />
                                    </button>
                                </div>
                            </div>
                            <div className="col-2 mb-3">
                                <div className="d-flex justify-content-end align-items-end h-100 ">
                                    <button
                                        className="check-availibility-button text-20 border-0"
                                        onClick={handleAddHotels}
                                    >
                                        Add Hotels
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="row gy-4">
                                {filteredHotels?.length !== 0 ? filteredHotels?.map((hotel) => (
                                    <div className="col-sm-6 col-12">
                                        <div className="hotel-list-border">
                                            <div className="row">
                                                <div className="col-sm-6 col-12">
                                                    {/* <img src={hotel.hotel_photo[0] ? hotel.hotel_photo[0] : '/hotel1.3.png'} className="hotel-card-image" alt="photos" /> */}
                                                    <Carousel style={{ height: "200px", width: "300px" }}>
                                                        {hotel?.hotel_photo?.map((e, index) => (
                                                            <Carousel.Item key={index} interval={2000} >
                                                                <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" style={{ borderRadius: "10px" }} />
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                                <div className="col-sm-6 col-12">
                                                    <div className="d-flex flex-column justify-content-between h-100">
                                                        <div>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="hotel-card-name">{hotel.hotel_name}</div>
                                                                <div>
                                                                    <StarRating
                                                                        averageStarRating={hotel.hotel_type}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="text14"><span className="font600">Location</span>: {hotel.city}, {hotel.state}</div>
                                                            <div className="text14"><span className="font600">Address</span>: {hotel.hotel_address}, {hotel.city}, {hotel.state}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <button className="check-availibility-button mb-2 px-4 texxt14 py-1" variant="primary" onClick={() => handleViewHotel(hotel)}>View</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div>No Hotels Added</div>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default MyHotels;
