// src/MapComponent.js

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const MapComponent = () => {
    const [position, setPosition] = useState([15.2993, 74.1240]); // Default position (Goa, India)
    const [input, setInput] = useState("");

    const handleSubmit = (e) => {
        // onLocationSubmit(true);
        e.preventDefault();
        const url = `https://nominatim.openstreetmap.org/search?format=json&q=${input}`;
        // fetch(url)
        //     .then(response => response.json())
        //     .then(data => {
        //         if (data && data.length > 0) {
        //             const { lat, lon } = data[0];
        //             setPosition([lat, lon]);
        //             onLocationSubmit(true)
        //         } else {
        //             alert("Location not found");
        //             onLocationSubmit(false);
        //         }
        //     })
        //     .catch(error => {
        //         console.error("Error fetching location data:", error);
        //         alert("Error fetching location data");
        //     });
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className='mb-3 d-flex justify-content-between'>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Enter Location"
                    className="location-input"
                />
                <button type="submit" className="check-availibility-button text-20 border-0">Submit</button>
            </form>
            <MapContainer center={position} zoom={13} style={{ height: "400px", width: "100%" }} className='map-container'>
                <MapUpdater position={position} />
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={position}>
                    <Popup>
                        {input}
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};

const MapUpdater = ({ position }) => {
    const map = useMap();

    useEffect(() => {
        map.setView(position);
    }, [position, map]);

    return null;
};

export default MapComponent;
