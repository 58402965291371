import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelopeOpen, faUser, faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import { Button, Row } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ProfileSection from "./SecondNavbar";

const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return 'Invalid date format';
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
};

const Inquiries = () => {
    const navigate = useNavigate();

    const [inquiryList, setInquiryList] = useState([])
    console.log(inquiryList)

    const fetchItineraryHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/inquiry/getAll`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setInquiryList(data?.data)
    };

    useEffect(() => {
        fetchItineraryHotel();
    }, []);

    return (
        <>
            <Header2 />
            <div className="costum_container bg-white">
                <My_pannel />
                <div style={{ backgroundColor: "white", height: "100vh" }}>
                    <ProfileSection title="Inquiries" />

                    <section className="new-vendor-container">
                        <div className=''>
                            <div className=''>
                                <div>
                                    <table className="table table-bordered mt-2">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">Full Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Contact No</th>
                                                <th scope="col">Departure</th>
                                                <th scope="col">Total Traveller</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inquiryList && inquiryList?.map((e) => (
                                                <tr>
                                                    <td>
                                                        <h6 className="mb-0">{e?.fullname}</h6>
                                                        <span style={{ fontSize: "13px" }} className="mb-0">{e?.city},{e?.state}</span>
                                                    </td>
                                                    <td>{e?.email}</td>
                                                    <td>{e?.number}</td>
                                                    <td>{e?.departure}</td>
                                                    <td>
                                                        <p className="mb-0">Adult : {e?.total_adult}, Child : {e?.total_child}, Infant : {e?.total_infants}</p>
                                                    </td>
                                                    <td>{formatDate(e?.createdAt)}</td>
                                                    <td>
                                                        <a href={`/packega_details/${e?.package_id}`} target="_blank" >view</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Inquiries;
