import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const StarRating = ({ averageStarRating }) => {
  // Calculate the full stars and the partial fill
  const fullStars = Math.floor(averageStarRating);
  const partialFill = (averageStarRating - fullStars) * 100;

  return (
    <span className="text16 font500">
      {/* Render full stars */}
      {[...Array(fullStars)].map((_, i) => (
        <FontAwesomeIcon key={i} icon={faStar} className="c_icon" style={{ color: "#FFD700" }} />
      ))}

      {/* Render partially filled star if needed */}
      {fullStars < 5 && partialFill > 0 && (
        <span className="star-wrapper" style={{ position: "relative", display: "inline-block" }}>
          <FontAwesomeIcon icon={faStar} className="c_icon" style={{ color: "#B8B8B8" }} />
          <span
            className="star-overlay"
            style={{
              width: `${partialFill}%`,
              color: "#FFD700",
              position: "absolute",
              top: 0,
              left: 0,
              overflow: "hidden",
            }}
          >
            <FontAwesomeIcon icon={faStar} className="c_icon" />
          </span>
        </span>
      )}

      {/* Render empty stars to reach a total of 5 */}
      {[...Array(5 - fullStars - (partialFill > 0 ? 1 : 0))].map((_, i) => (
        <FontAwesomeIcon key={i + fullStars + 1} icon={faStar} className="c_icon" style={{ color: "#B8B8B8" }} />
      ))}

      {/* Display the average star rating */}
      <span style={{ color: "" }}>({averageStarRating})</span>
    </span>
  );
};

export default StarRating;
