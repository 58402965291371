import React, { useState, useEffect, useRef } from "react"
import { Container, Modal, Row } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Country, State, City } from "country-state-city"
import Select from "react-select"
import { BASE_URL } from "../BASE_URL"
import Header from "../Project/Header"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import countries from "../CountryStateCity.json"
import loader from "../images/loading.png"
import { number } from "yup"
import axios from "axios"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Vandor_Registation = () => {

  const [loading, setLoading] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)

  const navigate = useNavigate()
  const [value, setValue] = React.useState(0)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);


  const [registerAgency, setRegisterAgency] = useState({
    full_name: "",
    agency_name: "",
    mobile_number: "",
    email_address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    password: "",
    business_type: "",

    GST_no: "",
    agency_logo: "",
    website: "",
    IATA: "",
    agency_address: "",
    status: "",

  })

  const [full_name, setFull_name] = useState("")
  const [agency_name, setAgency_name] = useState("")
  const [mobile_number, setMobile_number] = useState("")
  const [email_address, setEmail_address] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [BusinessType, setBusinessType] = useState("")
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedState, setSelectedState] = useState("")
  const [selectedCity, setSelectedCity] = useState("")
  const [pincode, setPincode] = useState("")

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const [backButton, setBackButton] = useState(false)

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem('formData'));

    if (localData && backButton) {

      const selectedCountry = countries.find((e) => e.name === localData?.selectedCountry)
      const selectedState = selectedCountry?.states?.find((e) => e.name === localData?.selectedState)
      setStates(selectedCountry?.states)
      setCities(selectedState?.cities)

      setFull_name(localData?.full_name)
      setAgency_name(localData?.agency_name)
      setMobile_number(localData?.mobile_number)
      setEmail_address(localData?.email_address)
      setPassword(localData?.password)
      setConfirmPassword(localData?.password)
      setBusinessType(localData?.business_type)
      setSelectedCountry(localData?.selectedCountry)
      setSelectedState(localData?.selectedState)
      setSelectedCity(localData?.selectedCity)
      setPincode(localData?.pincode)
    }
  }, [backButton]);

  const [gst, setGst] = useState("")
  const [address, setAddress] = useState("")
  const [logo, setLogo] = useState(null)
  const [website, setWebsite] = useState("")
  const [isIATARegistered, setIsIATARegistered] = useState(null);

  const handleCountryChange = (e) => {
    const selectedCountryName = e.target.value;
    setSelectedState("")
    setSelectedCity("")
    setSelectedCountry(selectedCountryName)
    const selectedCountry = countries.find((e) => e.name === selectedCountryName)
    setStates(selectedCountry?.states)
  }

  const handleStateChange = (e) => {
    const selectedStateName = e.target.value;
    setSelectedCity("")
    setSelectedState(selectedStateName)
    const selectedState = states.find((e) => e.name === selectedStateName)
    setCities(selectedState?.cities)
  }

  // Step 2: Handle the change event to update the state
  const handleRadioChange = (event) => {
    setIsIATARegistered(event.target.value);
  };

  const handleLogoSelect = (e) => {
    const { files } = e.target;
    setLogo(files[0])
  }

  const handlePasswordChange = (event) => {
    const value = event.target.value
    setPassword(value)
  }

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value
    setConfirmPassword(value)
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };


  const handleNextClick = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (full_name.trim() === "") {
      toast.error("Please enter full name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (agency_name.trim() === "") {
      toast.error("Please enter agency name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (mobile_number.trim() === "") {
      toast.error("Please enter mobile number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (mobile_number.length !== 10) {
      toast.error("Mobile number must be 10 digits!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (email_address.trim() === "") {
      toast.error("Please enter email id!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (!emailRegex.test(email_address)) {
      toast.error("Invalid email address!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (password.trim() === "") {
      toast.error("Please enter password", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (selectedCountry === "") {
      toast.error("Please select country!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (selectedState === "") {
      toast.error("Please select state!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (selectedCity === "") {
      toast.error("Please select city!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (pincode.trim() === "") {
      toast.error("Please enter pincode!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (pincode.length !== 6) {
      toast.error("Pincode must be 6 digits!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else if (BusinessType === "") {
      toast.error("Please select business type!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
    } else {

      const formData = {
        full_name: full_name.trim(),
        agency_name: agency_name.trim(),
        mobile_number: mobile_number.trim(),
        email_address: email_address.trim(),
        password: password.trim(),
        business_type: BusinessType.trim(),
        selectedCountry: selectedCountry,
        selectedState: selectedState,
        selectedCity: selectedCity,
        pincode: pincode.trim(),
      };

      localStorage.setItem("formData", JSON.stringify(formData));
      setValue(1)
    }
  }

  const handleNextClick1 = async () => {
    const storedData = JSON.parse(localStorage.getItem('formData'));

    if (!storedData) {
      toast.error("Local storage data not found", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    setLoading(true)

    // Step 2: Create a FormData object
    const formData = new FormData();

    // Append the fields from localStorage and the current state
    formData.append('full_name', storedData.full_name);
    formData.append('mobile_number', storedData.mobile_number);
    formData.append('email_address', storedData.email_address);
    formData.append('password', storedData.password);
    formData.append('country', storedData.selectedCountry);
    formData.append('state', storedData.selectedState);
    formData.append('city', storedData.selectedCity);
    formData.append('pincode', storedData.pincode);
    formData.append('agency_name', storedData.agency_name);
    formData.append('business_type', storedData.business_type); // Ensure this is part of the state or localStorage data
    formData.append('GST_NO', gst); // Add GST number from current state
    formData.append('agency_logo', logo); // Add the logo file from the state
    formData.append('website', website); // Add website from the current state
    formData.append('IATA', isIATARegistered); // Add IATA registration status from the state
    formData.append('agency_address', address); // Add the agency address from the state

    try {
      const res = await fetch(`${BASE_URL}agency`, {
        method: "POST",
        body: formData,
      });

      const data = await res.json();

      if (res.ok) {
        toast.success("Registration Successfully Done!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
        localStorage.removeItem('formData')
        setLoading(false)
        setVerifyLoading(false)
        navigate('/vendor/login')
      } else {
        setLoading(false)
        setVerifyLoading(false)
        console.error('Error:', data);
      }
    } catch (error) {
      setLoading(false)
      setVerifyLoading(false)
    }
  };

  //------------------- number validation

  const handleKeyDown = (e) => {
    // Allow: backspace, delete, tab, escape, enter
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A/Ctrl+C/Ctrl+V
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // Let it happen, don't do anything
      return
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault()
    }
  }

  const [show1, setShow1] = useState(false)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const [inputValues, setInputValues] = useState('');
  const [sentOtp, setsentOtp] = useState('');
  const inputRefs = useRef(Array.from({ length: 6 }, () => null));

  const createRef = (index) => (ref) => {
    inputRefs.current[index] = ref;
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      e.target.value = '';
    }

    const updatedValues = inputRefs.current.map((ref) => ref.value).join('');
    setInputValues(updatedValues);
  };

  const handleInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !e.target.value) {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const renderInputs = () => {
    const inputs = [];

    for (let i = 0; i < 6; i++) {
      inputs.push(
        <input
          key={i}
          type='text'
          maxLength={1}
          pattern="\d*"
          onChange={(e) => handleInputChange(i, e)}
          onKeyDown={(e) => handleInputKeyDown(i, e)}
          ref={createRef(i)}
          style={{
            width: '40px',
            height: '40px',
            margin: '0 5px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '16px',
            padding: '8px',
            textAlign: 'center',
          }}
        />
      );
    }

    return inputs;
  };

  const verifyOtp = async () => {

    if (!inputValues) {
      toast.error("Please Enter Otp!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      })
      return;
    }

    if (Number(inputValues).length < 6) {
      toast.error("Please Enter Valid Otp!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      })
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}agency/verify-otp`, {
        contact: Number(mobile_number),
        otp: Number(inputValues),
      });

      if (response.status === 200) {
        setVerifyLoading(true)
        handleNextClick1()
      } else {
        setVerifyLoading(false)
        toast.error("Invalid Otp!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
      }
    } catch (error) {
      setVerifyLoading(false)
      toast.error('Error verifying OTP. Please check your network connection and try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }

    // if (sentOtp === Number(inputValues)) {
    //   setVerifyLoading(true)
    //   handleNextClick1()
    // } else {
    //   setVerifyLoading(false)
    //   toast.error("Invalid Otp!", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 2000,
    //   })
    // }
  }

  const sendOtp = async () => {

    const storedData = JSON.parse(localStorage.getItem('formData'));

    if (!storedData) {
      toast.error("Local storage data not found", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    setLoading(true)

    try {
      const response = await axios.post(`${BASE_URL}agency/send-otp`, {
        contact: mobile_number,
      });

      if (response.status === 200) {
        setLoading(false)
        toast.success('OTP sent successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
        handleShow1()
      } else {
        setLoading(false)
        toast.error('Failed to send OTP. Please try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      setLoading(false)
      console.error('Error sending OTP:', error);
      toast.error('Error sending OTP. Please check your network connection and try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  }

  return (
    <>
      <Header />
      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <section className='py-5'>
            <Container>
              <div className='vendor_signup py-5'>
                <div className='sign_up_form py-xl-4 py-lg-3 py-md-2 py-sm-1'>
                  <div className='sign_up_header'>
                    <h3 className='text-center mb-3'>
                      Sign Up to your account
                    </h3>
                    <p>Personal Details</p>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Row className='gy-2 gx-5'>

                      {/*------------------------ full_name  -----------------------*/}
                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Full Name
                        </p>
                        <input
                          value={full_name}
                          type='text'
                          name='full_name'
                          className='w-100'
                          onChange={(e) => setFull_name(e.target.value)}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z ]/g,
                              "",
                            );
                          }}
                        />
                      </div>

                      {/*------------------------ agency Name  -----------------------*/}

                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal  text-20'>
                          Agency Name
                        </p>
                        <input
                          value={agency_name}
                          type='text'
                          name='agency_name'
                          onChange={(e) => setAgency_name(e.target.value)}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z ]/g,
                              "",
                            )
                          }}
                          id=''
                        />
                      </div>

                      {/*------------------------ mobile_number  -----------------------*/}
                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Mobile Number
                        </p>
                        <input
                          type='tel'
                          name='mobile_number'
                          value={mobile_number}
                          onChange={(e) => setMobile_number(e.target.value)}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 10);

                          }}
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      {/*------------------------ email  -----------------------*/}
                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Email
                        </p>
                        <input
                          type='email'
                          value={email_address}
                          name='email_address'
                          onChange={(e) => setEmail_address(e.target.value)}
                        />
                      </div>

                      {/*------------------------ pincode  -----------------------*/}
                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Pincode
                        </p>
                        <input
                          value={pincode}
                          type='tel'
                          name='pincode'
                          onChange={(e) => setPincode(e.target.value)}
                          maxLength={6}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 6);

                          }}
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      {/*------------------------ country  -----------------------*/}
                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Choose Country
                        </p>

                        <select
                          name='country'
                          value={selectedCountry}
                          onChange={handleCountryChange}>
                          <option value=''>Select</option>
                          {countries.map((e) => (
                            <option value={e.name}>{e.name}</option>
                          ))}
                        </select>
                      </div>

                      {/*------------------------ state  -----------------------*/}
                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Choose State
                        </p>
                        <select
                          name='state'
                          value={selectedState}
                          onChange={handleStateChange}>
                          <option value=''>Select</option>
                          {states && states.map((e) => (
                            <option value={e.name}>{e.name}</option>
                          ))}
                        </select>
                      </div>

                      {/*------------------------ city  -----------------------*/}
                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Choose City
                        </p>
                        <select
                          name='city'
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.target.value)}>
                          <option value=''>Select</option>
                          {cities && cities.map((e) => (
                            <option value={e.name}>{e.name}</option>
                          ))}
                        </select>
                      </div>

                      {/*------------------------ password  -----------------------*/}
                      <div className='col-6 py-1' style={{ position: 'relative' }}>
                        <p className='cmnp mb-2 fw-normal text-20'>Password</p>
                        <input
                          type={passwordVisible ? "text" : "password"}
                          name='password'
                          value={password}
                          className='py-1 px-3'
                          onChange={handlePasswordChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                              "",
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px solid #09646d",
                          }}
                        />
                        <img
                          src={passwordVisible ? "/eye.png" : "/eye2.png"}
                          className='eye-image'
                          alt=''
                          style={{
                            height: "10px",
                            width: "15px",
                            position: "absolute",
                            top: "30px",
                            bottom: "0",
                            right: "9%",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={togglePasswordVisibility}
                        />
                      </div>
                      {password.length < 6 && password.length !== 0 && (
                        <div style={{ color: "red" }}>
                          Enter Password (at least 6 characters)
                        </div>
                      )}

                      {/*------------------------ confirm-password  -----------------------*/}
                      <div className='col-6 py-1' style={{ position: 'relative' }}>
                        <p className='cmnp mb-2 fw-normal text-20'>Confirm Password</p>
                        <input
                          type={confirmPasswordVisible ? "text" : "password"}
                          name='confirm_password'
                          value={confirmPassword}
                          className='py-1 px-3'
                          onChange={handleConfirmPasswordChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                              "",
                            );
                          }}
                        />
                        <img
                          src={confirmPasswordVisible ? "/eye.png" : "/eye2.png"}
                          className='eye-image'
                          alt=''
                          style={{
                            height: "10px",
                            width: "15px",
                            position: "absolute",
                            top: "30px",
                            bottom: "0",
                            right: "9%",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={toggleConfirmPasswordVisibility}
                        />
                      </div>
                      {confirmPassword !== password && (
                        <div style={{ color: "red" }}>
                          Password and Confirm Password do not match
                        </div>
                      )}

                      {/*----------------------------- bussiness_type ------------------------------------*/}

                      <div className='col-6 py-1'>
                        <p className='cmnp mb-2 fw-normal  text-20'>
                          Business Type
                        </p>
                        <select name='business_type' value={BusinessType} id='' onChange={(e) => setBusinessType(e.target.value)}>
                          <option value=''>Select</option>
                          <option value='Sole Proprietor'>
                            Sole Proprietor
                          </option>
                          <option value='Partnership'>Partnership</option>
                          <option value='Joint Venture'>PVT LTD. CO.</option>
                          <option value='HUF'>HUF</option>
                          <option value='Limited'>Limited</option>
                          <option value='Sole Proprietor with GST'>
                            Sole Proprietor with GST
                          </option>
                          <option value='Sole Proprietor Without GST'>
                            Sole Proprietor Without GST
                          </option>
                          <option value='Partnership LLP'>
                            Partnership LLP
                          </option>
                          <option value='Partnership Non LLP'>
                            Partnership Non LLP
                          </option>
                          <option value='Corporate'>Corporate</option>
                        </select>
                      </div>

                    </Row>
                  </form>
                  <div className='text-center mt-4'>
                    <button
                      type='button'
                      className='check-availibility-button text-20'
                      onClick={handleNextClick}
                    >
                      Next
                    </button>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </Container>


          </section>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <section className='py-5'>
            <Container>
              <div className='vendor_signup py-5'>
                <div className='sign_up_form py-xl-4 py-lg-3 py-md-2 py-sm-1'>
                  <div className='sign_up_header'>
                    <h3 className='text-center mb-3'>
                      Sign Up to your account
                    </h3>
                    <p>Agency Details</p>
                  </div>
                  <form>
                    <Row className='gy-2 gx-5'>

                      {/*----------------------------- Agency GST no ------------------------------------*/}

                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                        <p className='cmnp mb-2 fw-normal  text-20'>
                          Agency GST No
                        </p>
                        <input
                          type='text'
                          name='GST_no'
                          onChange={(e) => setGst(e.target.value)}
                          value={gst}
                          id=''
                          maxLength={15}
                        />
                      </div>

                      {/*------------------------ agency address  -----------------------*/}

                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                        <p className='cmnp mb-2 fw-normal text-20'>
                          Agency Address

                        </p>
                        <input
                          type='text'
                          name='agency_address'
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          id=''
                        />
                      </div>

                      {/*----------------------------- Agency_logo ------------------------------------*/}

                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                        <p className='cmnp mb-2 fw-normal  text-20'>
                          Agency Logo
                        </p>
                        <input type='file' name='agency_logo' id='' onChange={handleLogoSelect} />
                      </div>

                      {/*----------------------------- website_url ------------------------------------*/}

                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                        <p className='cmnp mb-2 fw-normal  text-20'>
                          Website url
                        </p>
                        <input
                          type='text'
                          name='website'
                          onChange={(e) => setWebsite(e.target.value)}
                          value={website}
                          id=''
                        />
                      </div>

                      {/*----------------------------- IATA_registered ------------------------------------*/}

                      <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1'>
                        <div className='radio_input d-flex align-items-center'>
                          <p className='mb-0'>IATA Registered:</p>
                          <div className='d-flex align-items-center ms-2'>
                            <input
                              type='radio'
                              name='IATA'
                              value='Yes'
                              id='IATA-yes'
                              checked={isIATARegistered === 'Yes'}
                              onChange={handleRadioChange}
                              className='ms-2'
                            />
                            <label htmlFor='IATA-yes' className='ms-1'>
                              Yes
                            </label>
                            <input
                              type='radio'
                              name='IATA'
                              value='No'
                              id='IATA-no'
                              checked={isIATARegistered === 'No'}
                              onChange={handleRadioChange}
                              className='ms-2'
                            />
                            <label htmlFor='IATA-no' className='ms-1'>
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div className='row'>
                      <div className='text-center col-12 col-sm-6 sing_up_button'>
                        <Button
                          className='w-100 w-sm-50'
                          onClick={() => { setValue(0); setBackButton(true) }}
                          style={{
                            backgroundColor: "lightgray",
                            color: "black",
                            border: "none",
                          }}
                        >
                          Back
                        </Button>
                      </div>
                      <div className='text-center col-12 col-sm-6 sing_up_button'>
                        {loading ? (
                          <Button
                            className='w-100 w-sm-50'
                          >
                            <img src={loader} alt="" className="spinner" style={{ height: "80%" }} />
                          </Button>
                        ) : (
                          <Button
                            className='w-100 w-sm-50'
                            onClick={sendOtp}
                          >
                            Register
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Container>
          </section>
        </TabPanel>

      </Box>

      <Modal show={show1} onHide={handleClose1} backdrop="static">
        <Modal.Body>
          <div>
            <Container>
              <div className='common_p pt-3 '>
                <div className='inerp border-1 pb-0'>
                  <>
                    <div className='col-12'>
                      <form>
                        <div>
                          <h6 style={{ fontWeight: "600", fontSize: "18px", marginBottom: "10px", textAlign: "center" }}>
                            Enter OTP
                          </h6>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {renderInputs()}
                          </div>
                        </div>
                        <div className='d-flex justify-content-center gap-3 mt-3'>
                          <p
                            type='submit'
                            className="registration_otp_close_btn"
                            onClick={handleClose1}
                          >
                            Close
                          </p>
                          {verifyLoading ? (
                            <p
                              type='submit'
                              className="registration_otp_verify_btn"
                            >
                              <img src={loader} alt="" className="spinner" />
                            </p>
                          ) : (
                            <p
                              type='submit'
                              className="registration_otp_verify_btn"
                              onClick={verifyOtp}
                            >
                              Verify
                            </p>
                          )}
                        </div>
                      </form>
                    </div>
                  </>
                </div>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Vandor_Registation
