import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";

import './css/HotelBooking.css'
import ProfileSection from "./SecondNavbar";

function convertDate(date) {
    // Split the date into components
    const [year, month, day] = date.split('-');
    
    // Return the date in dd-mm-yyyy format
    return `${day}-${month}-${year}`;
  }

const HotelBookings = () => {
    const navigate = useNavigate();

    const [bookings, setBookings] = useState([]);

    const HotelBookingAll = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}hotel_booking_syt/all`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setBookings(data.data);
        console.log(data);

    };

    useEffect(() => {
        HotelBookingAll();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    const [statusFilter, setStatusFilter] = useState('');

    const handleStatusChange = (event) => {
        setStatusFilter(event.target.value);
    };

    const filteredBookings = bookings && bookings.filter(booking =>
        statusFilter === '' || booking.status === statusFilter
    );

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
                    <ProfileSection title="Booked Hotels" />
                    <section className="new-vendor-container">
                        <div className="filter-container">
                            <div className="filter-title">Booking Status :</div>
                            <select onChange={handleStatusChange} value={statusFilter}>
                                <option value="">All</option>
                                <option value="booked">Booked</option>
                                <option value="reject">Rejected</option>
                                <option value="approve">Approved</option>
                                <option value="cancel">Cancelled</option>
                            </select>
                        </div>
                        {bookings && <table className="booking-table">
                            <thead className="booking-table__thead">
                                <tr className="booking-table__tr">
                                    <th className="booking-table__th">Hotel Details</th>
                                    <th className="booking-table__th">Status</th>
                                    <th className="booking-table__th">Total Persons</th>
                                    <th className="booking-table__th">Total Rooms Booked</th>
                                    <th className="booking-table__th">Check-in Date</th>
                                    <th className="booking-table__th">Check-out Date</th>
                                    <th className="booking-table__th">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="booking-table__tbody">
                                {filteredBookings.map((booking) => (
                                    <tr key={booking._id} className="booking-table__tr">
                                        <td className="booking-table__td">
                                            {booking.hotel_details.length > 0 ? (
                                                <>
                                                    <div className="booking-table__hotel-name">{booking.hotel_details[0].hotel_name}</div>
                                                    {/* <div className="booking-table__hotel-id">{booking.hotel_id}</div> */}
                                                    <div className="booking-table__hotel-address">{booking.hotel_details[0].hotel_address}</div>
                                                </>
                                            ) : (
                                                <div className="booking-table__no-hotel-details">No Hotel Details</div>
                                            )}
                                        </td>
                                        <td className="booking-table__td">{booking.status}</td>
                                        <td className="booking-table__td">{`${booking.total_adult} Adults + ${booking.total_child} Children`}</td>
                                        <td className="booking-table__td">{booking.total_booked_rooms || 'N/A'}</td>
                                        <td className="booking-table__td">{booking.check_in_date ? convertDate(booking.check_in_date?.slice(0, 10)) : 'N/A'}</td>
                                        <td className="booking-table__td">{booking.check_out_date ? convertDate(booking.check_out_date?.slice(0, 10)) : 'N/A'}</td>
                                        <td className="booking-table__td">
                                            <button className="booking-table__button" onClick={() => navigate(`/vendor/hotelbookings/${booking._id}`)}>View More</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                        {
                            !bookings && <div>No Bookings</div>
                        }
                    </section>

                </div>
            </div>
        </>
    );
};

export default HotelBookings;
