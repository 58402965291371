import {
  faArrowLeft,
  faBell,
  faCableCar,
  faEnvelopeOpen,
  faLocationPin,
  faPenToSquare,
  faPlaneArrival,
  faPlaneDeparture,
  faTag,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import "../Project/css/index1.css"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import Side_navbar from "./Side_navbar"
import My_pannel from "./My_pannel"
import Header from "../Project/Header"
import Header2 from "./Header2"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import ProfileSection from "./SecondNavbar"

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Format options to get the date in 'dd-mm-yyyy' format
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-GB', options);
};

function Display_custom(props) {
  const navigate = useNavigate()

  const { id } = useParams()

  const [showPackageData, setShowPackageData] = useState([])
  console.log(showPackageData?.length)


  const showPackages = async () => {
    const token = localStorage.getItem("vendorToken")

    const res = await fetch(`${BASE_URL}customrequirements/Agencyshowdata`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    console.log(data?.data)
    setShowPackageData(data.data)
  }

  useEffect(() => {
    showPackages()
  }, [])

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div>
          <ProfileSection title="Custome Requirements" />

          <section className='new-vendor-container'>
            <div className='custome-req-list-grid'>
              {showPackageData &&
                showPackageData.map((ele, i) => {
                  return (
                    <>
                      <div className='' key={ele._id}>
                        <div
                          className='cutome-req-body-grid only_for_green_border p-xl-3 p-lg-3 p-md-3 p-sm-3 p-2 costum_requirments'
                          style={{
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            height: "auto",
                          }}
                          onClick={() =>
                            navigate(`/display/custom/details/${ele._id}`)
                          }
                        >
                          <div className='costum_requirments_span'>
                            <span className='cmnp cmnbkg'>{i + 1}</span>
                          </div>
                          <div className='costum_requirments_center'>
                            <div className='d-flex align-items-center justify-content-between pt-2 py-1'>
                              <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className='cmnclr text16'
                                />
                                <p className='cmnp ps-2 text-capitalize text16'>
                                  {ele.full_name}
                                </p>
                              </div>
                              <div>
                                <p className='cmnp ps-1 text-capitalize text16'>
                                  <b>Post On : </b>
                                  <span
                                    className="text16"
                                    style={{
                                      fontWeight: "500",
                                      // color: getStatusColor(ele.bid_status),
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {formatDate(ele.createdAt)}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faPlaneDeparture}
                                className='cmnclr text16'
                              />
                              <p className='cmnp ps-1 text-capitalize text16'>
                                <b>Departure : </b> {ele.departure}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faPlaneArrival}
                                className='cmnclr text16'
                              />
                              <p className='cmnp ps-1 text-capitalize text16'>
                                <b>Destination : </b> {ele.destination}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className='cmnclr text16'
                              />
                              <p className='cmnp ps-2 text16'>
                                <b>Travel Between :</b>
                                {formatDate(ele.start_date)} to{" "}
                                {formatDate(ele.end_date)}
                              </p>
                            </div>
                            <div className='for_order_only mt-2'>
                              <button
                                href=''
                                className='costum_requirments_footer_button text16 py-1'
                              >
                                <FontAwesomeIcon
                                  icon={faTag}
                                  className='me-2 text16'
                                />
                                Budget : {ele.budget_per_person} / Person
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              {showPackageData?.length < 1 && (
                <div>
                  <p>Leads Not Available.</p>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Display_custom
