import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faLocationPin, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import "./css/Hotels.css";
import ProfileSection from "./SecondNavbar";
import countries from "../CountryStateCity.json";
import StarRating from "../Project/StarRating";

const SelectHotel = () => {
    const states = countries.find((e) => e.name === "India")?.states || [];
    const navigate = useNavigate();
    const location = useLocation();

    const [hotels, setHotels] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [cities, setCities] = useState([]);
    const [selectedStar, setSelectedStar] = useState(""); // Add state for star filter

    // Fetch Hotels from API
    const fetchHotels = async () => {
        try {
            const token = localStorage.getItem("vendorToken");
            const response = await fetch(
                `${BASE_URL}api/hotel_itienrary/displayAgencyById`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();
            setHotels(data.data);
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchHotels();
    }, []);

    // Handle state change and set cities dynamically
    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        const filteredCities = states.find((s) => s.name === state)?.cities || [];
        setCities(filteredCities);
        setSelectedCity("");
    };

    // Handle Star Filter Change
    const handleStarChange = (e) => {
        setSelectedStar(e.target.value);
    };

    const getStarRatingFromHotelType = (hotelType) => {
        const hotelTypeToStars = {
            "5 star": 5,
            "4 star": 4,
            "3 star": 3,
            "2 star": 2,
            "1 star": 1,
        };
        return hotelTypeToStars[hotelType.toLowerCase()] || 0;
    };

    // Filter hotels based on search term, state, city, and star rating
    const filteredHotels = hotels.filter((hotel) => {
        const starRating = getStarRatingFromHotelType(hotel.hotel_type);
        return (
            hotel.hotel_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedState ? hotel.hotel_state === selectedState : true) &&
            (selectedCity ? hotel.hotel_city === selectedCity : true) &&
            (selectedStar ? starRating === parseInt(selectedStar) : true)
        );
    });

    const handleNavigate = () => {
        if (selectedHotel) {
            localStorage.setItem("selectedHotelId", selectedHotel);
            navigate(-1);
        }
    };

    const handleHotelSelection = (hotelId) => {
        setSelectedHotel(hotelId);
    };

    useEffect(() => {
        // Auto-select hotel if `location.state` is provided
        if (location.state) {
            setSelectedHotel(location.state);
        }
    }, [location.state]);

    const [isRotating, setIsRotating] = useState(false);

    const handleClearFilters = () => {
        setIsRotating(true);
        setTimeout(() => setIsRotating(false), 500);
        setSearchTerm("");
        setSelectedState("");
        setSelectedCity("");
        setCities([]);
        setSelectedStar("");
    };

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ProfileSection title="Select Hotel" />
                <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
                    <section className="new-vendor-container">
                        <section>
                            <div className="">
                                <div className="row">
                                    {/* Hotel Name Search */}
                                    <div className="col-3 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">Search Hotel Name</p>
                                        <input
                                            type="text"
                                            className="hotels-filter-input-css"
                                            placeholder="Search by hotel name..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    {/* State Filter */}
                                    <div className="col-2 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">State</p>
                                        <select
                                            className="hotels-filter-input-css"
                                            onChange={handleStateChange}
                                            value={selectedState}
                                        >
                                            <option value="">Select State</option>
                                            {states.map((state, index) => (
                                                <option key={index} value={state.name}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* City Filter */}
                                    <div className="col-2 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">City</p>
                                        <select
                                            className="hotels-filter-input-css"
                                            onChange={(e) => setSelectedCity(e.target.value)}
                                            value={selectedCity}
                                        >
                                            <option value="">Select City</option>
                                            {cities.map((city, index) => (
                                                <option key={index} value={city.name}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* Star Filter */}
                                    <div className="col-2 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">Star</p>
                                        <select
                                            className="hotels-filter-input-css"
                                            onChange={handleStarChange}
                                            value={selectedStar}
                                        >
                                            <option value="">Select Star</option>
                                            <option value="1">1 Star</option>
                                            <option value="2">2 Star</option>
                                            <option value="3">3 Star</option>
                                            <option value="4">4 Star</option>
                                            <option value="5">5 Star</option>
                                        </select>
                                    </div>
                                    <div className="col-1 mb-3">
                                        <div className="d-flex align-items-end h-100">
                                            <button
                                                className={`reset-button-css text-20 border-0 px-3`}
                                                onClick={handleClearFilters}
                                            >
                                                <FontAwesomeIcon icon={faSyncAlt} className={`${isRotating ? "rotate-icon" : ""}`} size="lg" title="Clear Filters" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-2 mb-3">
                                        <div className="d-flex justify-content-end align-items-end h-100 ">
                                            <button
                                                className="check-availibility-button text-20 border-0"
                                                onClick={handleNavigate}
                                            >
                                                Go To Itinerary
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Hotel List */}
                                <div className="col-12">
                                    {filteredHotels.length > 0 ? (
                                        [...filteredHotels].reverse().map((ele) => (
                                            <div key={ele._id} className="top-border p-4 mb-2">
                                                <div className="row gx-5">
                                                    <div className="col-lg-4 col-md-5 col-sm-6 text-center d-flex align-items-center">
                                                        <img
                                                            src={ele.hotel_photo[0]}
                                                            className="img-fluid w-100"
                                                            alt="hotel"
                                                            style={{ height: "200px" }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-8 col-md-7 col-sm-6 d-flex flex-column justify-content-start pe-4">
                                                        <div className="align-items-top top_hotels_name d-flex justify-content-between mb-1">
                                                            <h3 className="text18 font700 mb-0">
                                                                {ele.hotel_name}
                                                            </h3>
                                                            <input
                                                                type="radio"
                                                                name="selectedHotel"
                                                                value={ele._id}
                                                                checked={selectedHotel === ele._id}
                                                                onChange={() => handleHotelSelection(ele._id)}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <StarRating
                                                                averageStarRating={getStarRatingFromHotelType(
                                                                    ele.hotel_type
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="top_hotels_map">
                                                            <p className="top-a text14">
                                                                <FontAwesomeIcon icon={faLocationPin} />{" "}
                                                                {ele.hotel_city}, {ele.hotel_state}
                                                            </p>
                                                            <p className="top-a text14">
                                                                {ele.hotel_address}
                                                            </p>
                                                        </div>
                                                        <div className="top_hotels_star pt-2">
                                                            {Array.from(
                                                                { length: Math.floor(ele.hotel_review) },
                                                                (_, i) => (
                                                                    <FontAwesomeIcon
                                                                        key={i}
                                                                        icon={faStar}
                                                                        className="top_font_crl"
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No hotels found matching your criteria.</p>
                                    )}
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </>
    );
};

export default SelectHotel;





{/* <div
                                        className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-xl-block d-lg-none d-md-none d-sm-none d-none  text-start"
                                        style={{
                                            position: "sticky",
                                            top: "0px",
                                        }}
                                    >
                                        <div className="sort-list filter px-3 py-3">
                                            <div>
                                                <ul className="list-none ps-0">
                                                    <li className="d-flex justify-content-between">
                                                        <h6 className="filter-headers text16 font700">Sort by</h6>
                                                        <div className="reset-filter-button">
                                                            <button className="text14"
                                                            // onClick={() => setFilterCriteria({
                                                            //     sort: "",
                                                            //     hotelName: "",
                                                            //     priceRange: "",
                                                            // })}
                                                            >RESET</button>
                                                        </div>
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="sort"
                                                            value="highToLow"
                                                            className="input-margin"
                                                        // checked={filterCriteria.sort === "highToLow"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        High To Low
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="sort"
                                                            value="lowToHigh"
                                                            className="input-margin"
                                                        // checked={filterCriteria.sort === "lowToHigh"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        Low To High
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="sort"
                                                            value="popularity"
                                                            className="input-margin"
                                                        // checked={filterCriteria.sort === "popularity"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        Popularity
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mb-3 ">
                                                <label htmlFor="" className="filter-headers mb-1 text16 font700">
                                                    Enter Hotel Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="hotelName"
                                                    // value={filterCriteria.hotelName}
                                                    // onChange={handleFilterChange}
                                                    className="px-2 py-1 focus-outline"
                                                    style={{
                                                        borderRadius: "10px",
                                                        border: "1px solid lightgrey",
                                                    }}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <h6 className="filter-headers text18 font700">Filters</h6>
                                            </div>
                                            <div>
                                                <ul className="list-none ps-0">
                                                    <li>
                                                        <h6 className="filters-inner text16 font700">Hotel Price Range</h6>
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="priceRange"
                                                            value="lessThan10k"
                                                            className="input-margin"
                                                        // checked={filterCriteria.priceRange === "lessThan10k"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        Less than 10,000
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="priceRange"
                                                            value="10kTo20k"
                                                            className="input-margin"
                                                        // checked={filterCriteria.priceRange === "10kTo20k"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        10,000 to 20,000
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="priceRange"
                                                            value="20kTo40k"
                                                            className="input-margin"
                                                        // checked={filterCriteria.priceRange === "20kTo40k"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        20,000 to 40,000
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="priceRange"
                                                            value="40kTo60k"
                                                            className="input-margin"
                                                        // checked={filterCriteria.priceRange === "40kTo60k"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        40,000 to 60,000
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="priceRange"
                                                            value="60kTo80k"
                                                            className="input-margin"
                                                        // checked={filterCriteria.priceRange === "60kTo80k"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        60,000 to 80,000
                                                    </li>
                                                    <li className="text14">
                                                        <input
                                                            type="radio"
                                                            name="priceRange"
                                                            value="above80k"
                                                            className="input-margin"
                                                        // checked={filterCriteria.priceRange === "above80k"}
                                                        // onChange={handleFilterChange}
                                                        />
                                                        Above 80,000
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}