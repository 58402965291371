/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Project/css/index1.css";
import Header2 from "./Header2";
import { Form, Row, Button, Modal } from "react-bootstrap";
import My_pannel from "./My_pannel";
import { BASE_URL } from "../BASE_URL";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../images/loading.png";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const PackageItineraryUpdate = () => {

    const location = useLocation();

    const hotelType = "";
    const totalItinerary = location.state;
    const selectedTitle = localStorage.getItem('title')

    const [selectedHotel1, setSelectedHotel1] = useState(localStorage.getItem('selectedHotelId'))

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [hotelList, setHotelList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(null);


    const navigate = useNavigate();
    const [BidData, setBidData] = useState([]);

    const { packageId, _id } = useParams();

    const [editorData, setEditorData] = useState('');

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const [title, setTitle] = useState("")
    const [day, setDay] = useState(1)
    const [photo, setPhoto] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)

    useEffect(() => {
        setSelectedHotel(selectedHotel1)
        setTitle(selectedTitle)
    }, []);

    const getItinerary = async () => {
        const res = await fetch(`${BASE_URL}package/getPackageData?package_id=${packageId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        const foundItinerary = data?.data?.[0]?.Itinaries?.find((e) => e?._id === _id)
        console.log(foundItinerary)
        setDay(foundItinerary?.day)
        setHotelList(foundItinerary?.hotel_name)
        setTitle(foundItinerary?.title)
        setEditorData(foundItinerary?.activity)
        setSelectedImage(foundItinerary?.photo)

        const selectedHotelId = localStorage.getItem('selectedHotelId')

        if (selectedHotelId === undefined || selectedHotelId === "" || selectedHotelId === null) {
            console.log(selectedHotelId)
            setSelectedHotel1(foundItinerary?.hotel_itienrary_id)
        }
    };
    useEffect(() => {
        getItinerary();
    }, [_id]);

    const [error, setError] = useState(null);

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];

        if (file && file.size > 5 * 1024 * 1024) {
            setError("File size should be less than 5MB.");
            e.target.value = ''; // Clear the input to reset the selection
        } else if (file) {
            setError(null); // Clear the error if the file is valid

            // Create a preview URL for the selected image
            const previewUrl = URL.createObjectURL(file);
            setSelectedImage(previewUrl);

            // Optionally, store the file for further processing (e.g., upload)
            setPhoto(file);
        }
    };


    const fileInputRef = useRef(null);

    const AddItineriesData = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("vendorToken");

        if (!day || !title || !editorData) {
            toast.error("Please fill all details!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!photo && !selectedImage) {
            toast.error("Please select image!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (hotelType && hotelType !== "Not Required") {
            toast.error("Please fill all details!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (day > 10) {
            toast.error("Please Enter Valid Day!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("title", title);
        formData.append("activity", editorData);
        formData.append("photo", photo);
        if (hotelType === "Not Required") {
            // formData.append("hotel_itienrary_id", "");
        } else {
            formData.append("hotel_itienrary_id", selectedHotel1);
        }

        const res = await fetch(`${BASE_URL}itinerary?_id=${_id}`, {
            method: "PUT",
            headers: {
                Authorization: token,
            },
            body: formData,
        });

        if (res.status === 200) {
            localStorage.removeItem("selectedHotelId")
            localStorage.removeItem("title")
            setHotelList("")
            toast.success("Itinerary added successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            setTitle("");
            getItinerary();
            setSelectedHotel(null);
            setEditorData('');
            setTimeout(() => {
                navigate(-1);
            }, 1000);
            setLoading(false);

            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }

        } else {
            setLoading(false);
            toast.error("Failed to add itinerary!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };

    const handleNavigate = () => {
        localStorage.setItem("title", title)
        navigate('/vendor/select-hotel')
    }

    const fetchHotels = async () => {
        try {
            const token = localStorage.getItem("vendorToken");
            const response = await fetch(
                `${BASE_URL}api/hotel_itienrary/displayAgencyById`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();

            const selectedHotelName = data?.data?.find((e) => e._id === selectedHotel1)
            setHotelList(selectedHotelName?.hotel_name)
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchHotels();
    }, [selectedHotel1]);

    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <ToastContainer />
                <section>
                    <div className='costum_container'>
                        <div className=' p-3'>
                            <div>
                                <Form>
                                    <Row
                                        className='itinerary_padding green_border gy-2 gx-5 margin_left_right justify-content-center'
                                        style={{ backgroundColor: "#ffffff" }}
                                    >
                                        <div className='col-12'>
                                            <div className='row mb-3'>
                                                <div className='col-12'>
                                                    <span className='text-20 itinerary_header text20 font700'>
                                                        Day wise Itinerary plan
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row d-flex justify-content-center'>
                                            <div
                                                className='col-12'
                                                style={{
                                                    border: "1px solid grey",
                                                    borderRadius: "15px",
                                                }}
                                            >
                                                <div className='p-3'>
                                                    <div>
                                                        <div className='mb-2'>
                                                            <label htmlFor='text' className="text14 font600 mb-1">Day</label>
                                                            <input
                                                                type='number'
                                                                name='day'
                                                                value={day}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className='mb-2'>
                                                            <label htmlFor='text' className="text14 font600 mb-1">Add Title</label>
                                                            <input type='text' value={title} name='title' onChange={(e) => setTitle(e.target.value)} />
                                                        </div>
                                                        {hotelType && hotelType === "Not Required" ? (
                                                            <></>
                                                        ) : (
                                                            <div className='mb-2'>
                                                                <label htmlFor='text' className="text14 font600 mb-1">Select Hotel</label>
                                                                <div className='d-flex align-items-center'>
                                                                    <input
                                                                        type='text'
                                                                        name='select_hotel'
                                                                        className='flex-grow-1'
                                                                        value={hotelList}
                                                                        readOnly
                                                                    />
                                                                    <Button
                                                                        variant='primary'
                                                                        style={{
                                                                            backgroundColor: "#09646d",
                                                                            border: "none"
                                                                        }}
                                                                        className='ms-2'
                                                                        onClick={handleNavigate}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className='mb-2'>
                                                            <div className="mb-1">
                                                                <img
                                                                    src={selectedImage || 'defaultImageURL'}
                                                                    alt="Selected Preview"
                                                                    style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                                                                />
                                                            </div>
                                                            <label htmlFor='text' className="text14 font600 mb-1">Update Photo ( File Size Max 5MB )</label>
                                                            <input
                                                                type="file"
                                                                name="photo"
                                                                accept="image/*"
                                                                onChange={handlePhotoChange}
                                                            />
                                                            {error && <p className="mb-0 text12" style={{ color: 'red' }}>{error}</p>}
                                                        </div>
                                                        <div className='mb-2 ckeditor-p-tag d-flex flex-column gap-1'>
                                                            <a className="text14 font600" style={{ textDecoration: "none", color: "black" }}>Description/Activity</a>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={editorData}
                                                                onChange={handleEditorChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                    {loading ? (
                                                        <button
                                                            className='m-3'
                                                            style={{
                                                                width: "105px",
                                                                height: "32px",
                                                                backgroundColor: "#09646d",
                                                                border: "none",
                                                                color: "white",
                                                                borderRadius: '10px'
                                                            }}
                                                        >
                                                            <img src={loader} alt="" className="h-75 spinner" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className='m-3 check-availibility-button text-20 border-0'
                                                            // style={{
                                                            //   width: "160px",
                                                            //   height: "40px",
                                                            //   backgroundColor: "#09646d",
                                                            //   border: "none",
                                                            //   color: "white",
                                                            // }}
                                                            onClick={AddItineriesData}
                                                        >
                                                            Submit
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Modal */}
        </>
    );
};

export default PackageItineraryUpdate