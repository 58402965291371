import React, { useContext, useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import My_pannel from "./My_pannel"
import "../Project/css/index1.css"
import Header2 from "./Header2"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ProfileSection from "./SecondNavbar"
import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL } from "../BASE_URL"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useNavigate, useParams } from "react-router-dom"
import { Form, Modal, Row } from "react-bootstrap"
import loader from "../images/loading.png"
import Button from "react-bootstrap/Button"

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    }
}

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
};

const getNextDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add 1 to the current date

    return date.toISOString().split('T')[0]; // Return in yyyy-mm-dd format
};

function EditNewPackage(props) {

    const [value, setValue] = useState(0)

    const targetSectionRef = useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    useEffect(() => {
        if (value === 1 && targetSectionRef.current) {
            targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [value]);

    const { id } = useParams();
    const navigate = useNavigate();

    const mealRequired = ["Breakfast", "Lunch", "Dinner"];
    const [checkedItems, setCheckedItems] = useState("");
    const [Destcat, setDestcat] = useState([]);
    const [destinationList, setDestinationList] = useState("");
    const [hotelType, setHotelType] = useState("");
    const [includeServices, setIncludeServices] = useState([]);
    const [excludeServices, setexcludeServices] = useState([]);
    const [newService, setNewService] = useState("");
    const [exclude, setExclude] = useState("");
    const [openRangeModal, setOpenRangeModal] = useState(false);
    const [price, setPrice] = useState('');
    const [startDatePrice, setStartDatePrice] = useState(null);
    const [endDatePrice, setEndDatePrice] = useState(null);
    const [priceOfChild, setPriceOfChild] = useState('');
    const [priceOfInfants, setPriceOfInfants] = useState('');
    const [priceRanges, setPriceRanges] = useState([]);
    const [index, setIndex] = useState(null)
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [it, setIt] = useState([])
    console.log(it)
    const [totalItinerary, setTotalItinerary] = useState([])
    const [maxDaysOrNights, setMaxDaysOrNights] = useState(0);

    const [formData1, setFormData1] = useState({
        name: "",
        total_days: "",
        total_nights: "",
        destination: "",
        destination_category_id: "",
        meal_required: "",
        meal_type: "",
        travel_by: "",
        hotel_type: "",
        more_details: "",
        sightseeing: "",
        place_to_visit_id: "",
        status: "",
        package_type: "",
        room_sharing: "",
    });

    const handleOptionChange = (event) => {
        const { name, value } = event.target;
        setFormData1({ ...formData1, [name]: value })
    };

    const handleCheckboxChange = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setCheckedItems([...checkedItems, itemName]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== itemName));
        }
    };

    const handleCheckboxChange2 = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setDestinationList([...destinationList, itemName]);
        } else {
            setDestinationList(destinationList.filter((item) => item !== itemName));
        }
    };

    const handleCheckboxChange3 = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setHotelType([...hotelType, itemName]);
        } else {
            setHotelType(hotelType.filter((item) => item !== itemName));
        }
    };

    const handleAddService = () => {
        if (newService.trim() !== "") {
            setIncludeServices((prevServices) => [...prevServices, newService]);
            setNewService("");
        }
    };

    const handleRemoveService = (index) => {
        // Create a new array without the item to be removed
        const updatedServices = includeServices.filter((_, i) => i !== index);
        setIncludeServices(updatedServices);
    };

    const handleAddExclude = () => {
        if (exclude.trim() !== "") {
            setexcludeServices((prevServices) => [...prevServices, exclude]);
            setExclude(""); // Clear the input field after adding
        }
    };

    const handleRemoveExclude = (index) => {
        // Create a new array without the item to be removed
        const updatedServices = excludeServices.filter((_, i) => i !== index);
        setexcludeServices(updatedServices);
    };

    const handleOpenInsertModal = () => {
        setOpenRangeModal(true);
    };
    const handleCloseRangeModal = () => {
        setOpenRangeModal(false);
    };

    const getPackage = async () => {
        const token = localStorage.getItem("token")

        const res = await fetch(`${BASE_URL}package/getPackageData?package_id=${id}`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        console.log(data.data)

        const totalDays = data.data[0].total_days || 0;
        const totalNights = data.data[0].total_nights || 0;

        if (Number(totalDays) > Number(totalNights)) {
            setMaxDaysOrNights(totalDays);
        } else {
            setMaxDaysOrNights(totalNights);
        }

        setCheckedItems(data.data[0].meal_required || "")
        setDestinationList(data.data[0].destination_category_id.map((category) => category._id))
        setHotelType(data.data[0].hotel_type || "")
        setIncludeServices(data.data[0].include_service.map(ele => ele.include_services_value))
        setexcludeServices(data.data[0].exclude_service.map(ele => ele.exclude_services_value))
        setPriceRanges(data?.data?.[0]?.price_and_date)
        setIt(data?.data?.[0]?.Itinaries)
        setTotalItinerary(data?.data?.[0]?.Itinaries)

        setFormData1({
            name: data.data[0].name || "",
            total_days: data.data[0].total_days || "",
            total_nights: data.data[0].total_nights || "",
            destination: data.data[0].destination || "",
            meal_type: data.data[0].meal_type || "",
            travel_by: data.data[0].travel_by || "",
            more_details: data.data[0].more_details || "",
            sightseeing: data.data[0].sightseeing || "",
            place_to_visit_id: data.data[0].place_to_visit_id || "",
            status: data.data[0].status,
            room_sharing: data.data[0].room_sharing,
            package_type: data.data[0].package_type
        });
    };

    useEffect(() => {
        const fetchCategoryList = async () => {
            try {
                const token = localStorage.getItem("VendorToken");
                const response = await fetch(`${BASE_URL}destinationcategory`, {
                    headers: {
                        Authorization: token,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setDestcat(data.data);

            } catch (error) {

            }
        };
        fetchCategoryList();
    }, []);

    useEffect(() => {
        getPackage();
    }, []);

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tommorowDate = tomorrow.toISOString().split('T')[0];
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const formattedTomorrowDate = tomorrowDate.toISOString().split('T')[0];

    const handleStartDatePriceChange = (e) => {
        const newStartDate = e.target.value;
        setStartDatePrice(newStartDate);

        if (endDatePrice && new Date(endDatePrice) < new Date(newStartDate)) {
            setEndDatePrice('');
        }
    };

    const handleEndDatePriceChange = (e) => {
        setEndDatePrice(e.target.value);
    };

    const handleDelete = (index) => {
        setOpenDeleteDialogue(true)
        setIndex(index)
    };

    const handleCloseDeleteDialogue = () => {
        setOpenDeleteDialogue(false)
    }

    const handleConfirmDelete = () => {
        setOpenDeleteDialogue(false)
        const updatedPriceRanges = priceRanges.filter((_, i) => i !== index);
        setPriceRanges(updatedPriceRanges);
    };

    const handleSave = () => {

        if (!price || !priceOfChild || !priceOfInfants || !startDatePrice || !endDatePrice) {
            toast.error("Please Fill All Fields", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (Number(price) < Number(priceOfChild)) {
            toast.error("Childrens Price can not bigger then Adult Price", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (Number(price) < Number(priceOfInfants)) {
            toast.error("Infants Price can not bigger then Adult Price", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (Number(priceOfChild) < Number(priceOfInfants)) {
            toast.error("Infants Price can not bigger then Childrens Price", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const startDate = new Date(startDatePrice);
        const endDate = new Date(endDatePrice);

        // Count the number of objects where startDatePrice or endDatePrice is in between the range of price_start_date and price_end_date
        const overlappingRanges = priceRanges.filter((item) => {
            const priceStart = new Date(item.price_start_date);
            const priceEnd = new Date(item.price_end_date);

            return (
                (startDate >= priceStart && startDate <= priceEnd) ||
                (endDate >= priceStart && endDate <= priceEnd)
            );
        });

        const overlapCount = overlappingRanges.length;

        if (overlapCount > 0) {
            toast.error(`Dates are mismatched with other dates  `, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (price && priceOfChild && priceOfInfants && startDatePrice && endDatePrice) {
            const newPriceRange = {
                price_per_person: Number(price),
                child_price: Number(priceOfChild),
                infant_price: Number(priceOfInfants),
                price_start_date: startDatePrice,
                price_end_date: endDatePrice,
            };

            // Add the new price range to the array
            setPriceRanges([...priceRanges, newPriceRange]);

            // Reset the form and close the modal
            setPrice('');
            setPriceOfChild('');
            setPriceOfInfants('');
            setStartDatePrice(getNextDate(endDatePrice));
            setStartDatePrice('');
            setEndDatePrice('');
            setOpenRangeModal(false);
        } else {
            toast.error("Please Fill All Fields", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        }
    }

    const handleNavigate = () => {
        navigate(`/vendor/add-package-itineries/${id}`, { state: maxDaysOrNights })
    }

    const handleSaveit = () => {

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData1.name) {
            toast.error("Add Name of the Package", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.total_days || isNaN(Number(formData1.total_days))) {
            toast.error("Add valid Total Days", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.total_nights || isNaN(Number(formData1.total_nights))) {
            toast.error("Add valid Total Nights", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!destinationList || destinationList.length === 0) {
            toast.error("Select Destination Category", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (checkedItems.length === 0) {
            toast.error("Select Meal Required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.meal_type) {
            toast.error("Select Meal Type", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.travel_by) {
            toast.error("Select Travel By", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!hotelType) {
            toast.error("Select Hotel Type", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.more_details) {
            toast.error("Add More Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.sightseeing) {
            toast.error("Add Sightseeing Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        // if (!selectplacetovisit || selectplacetovisit.length === 0) {
        //     toast.error("Select Places to Visit", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        //     return;
        // }
        if (!formData1.status) {
            toast.error("Select Status", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!includeServices || includeServices.length === 0) {
            toast.error("Add Included Services", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!excludeServices || excludeServices.length === 0) {
            toast.error("Add Excluded Services", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        // if (!startDate) {
        //     toast.error("Select Start Date", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        //     return;
        // }
        // if (!endDate) {
        //     toast.error("Select End Date", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        //     return;
        // }


        try {
            const token = localStorage.getItem("vendorToken");

            const response = await fetch(`${BASE_URL}package/${id}`, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: formData1.name,
                    total_days: formData1.total_days,
                    total_nights: formData1.total_nights,
                    destination_category_id: destinationList,
                    meal_required: checkedItems,
                    meal_type: formData1.meal_type,
                    travel_by: formData1.travel_by,
                    hotel_type: hotelType,
                    more_details: formData1.more_details,
                    price_and_date: priceRanges,
                    sightseeing: formData1.sightseeing,
                    status: formData1.status,
                    include_service: includeServices,
                    exclude_service: excludeServices,
                })
            });

            const result = await response.json();

            if (response.ok) {
                toast.success("Package Edited Successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate(`/vendor/displaymypackage/${id}`);
                }, 2000);
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <ToastContainer />
                <div>
                    <ProfileSection title="Update Package" />
                    <div className="p-2">
                        <div className='' ref={targetSectionRef}>
                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                        overflowX: "auto",
                                    }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label='basic tabs example'
                                    >
                                        <Tab label='Overview' {...a11yProps(0)} />
                                        <Tab
                                            label='Itinerary'
                                            {...a11yProps(1)}
                                        />
                                    </Tabs>
                                </Box>

                                <TabPanel value={value} index={0}>
                                    <section className='vendor_submit_packages'>
                                        <div className="new-vendor-container">
                                            <div className="vendoraddpackage-form">
                                                <div className="vendoraddpackage-flex">
                                                    <div className="vendoraddpackage-input-group">
                                                        <p className="mb-1 vendor-common-label text14 font600">Package Name</p>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            placeholder="Enter package name"
                                                            value={formData1.name}
                                                            onChange={handleOptionChange}
                                                            className="vendor-common-input"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="vendoraddpackage-flex">
                                                    <div className="vendoraddpackage-input-group">
                                                        <p className="mb-1 vendor-common-label text14 font600">Package Type</p>
                                                        <select
                                                            name="package_type"
                                                            value={formData1.package_type}
                                                            onChange={handleOptionChange}
                                                            className="vendor-common-input"
                                                        >
                                                            <option value="">Select Package Type</option>
                                                            <option value="Land Packge">Land Package</option>
                                                        </select>
                                                    </div>
                                                    <div className="vendoraddpackage-input-group">
                                                        <div className="vendoraddpackage-flex">
                                                            <div className="vendoraddpackage-input-group">
                                                                <p className="mb-1 vendor-common-label text14 font600">Total Days</p>
                                                                <select
                                                                    name="total_days"
                                                                    value={formData1.total_days}
                                                                    onChange={handleOptionChange}
                                                                    className="vendor-common-input"
                                                                >
                                                                    <option value="">Select days</option>
                                                                    {[...Array(30).keys()].map(day => (
                                                                        <option key={day + 1} value={day + 1}>{day + 1}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="vendoraddpackage-input-group">
                                                                <p className="mb-1 vendor-common-label text14 font600">Total Nights</p>
                                                                <select
                                                                    name="total_nights"
                                                                    value={formData1.total_nights}
                                                                    onChange={handleOptionChange}
                                                                    className="vendor-common-input"
                                                                    disabled={!formData1.total_days}
                                                                >
                                                                    <option value="">Select nights</option>
                                                                    {formData1.total_days && [-1, 0, 1].map(offset => {
                                                                        const nightValue = parseInt(formData1.total_days) + offset;
                                                                        if (nightValue > 0) {
                                                                            return <option key={nightValue} value={nightValue}>{nightValue}</option>;
                                                                        }
                                                                        return null;
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ display: "flex", flex: "1" }}>
                                                        <div className="vendoraddpackage-checkbox-group">
                                                            <label className="vendor-common-label text14 font600 mb-2">Meal Type</label>
                                                            <div className="vendoraddpackage-checkbox">
                                                                <input
                                                                    type="radio"
                                                                    name="meal_type"
                                                                    value="Any"
                                                                    checked={formData1.meal_type === "Any"}
                                                                    onChange={handleOptionChange}
                                                                />
                                                                <label className="text14 font500">Any</label>
                                                            </div>
                                                            <div className="vendoraddpackage-checkbox">
                                                                <input
                                                                    type="radio"
                                                                    name="meal_type"
                                                                    value="Veg"
                                                                    checked={formData1.meal_type === "Veg"}
                                                                    onChange={handleOptionChange}
                                                                />
                                                                <label className="text14 font500">Veg</label>
                                                            </div>
                                                            <div className="vendoraddpackage-checkbox">
                                                                <input
                                                                    type="radio"
                                                                    name="meal_type"
                                                                    value="Non-Veg"
                                                                    checked={formData1.meal_type === "Non-Veg"}
                                                                    onChange={handleOptionChange}
                                                                />
                                                                <label className="text14 font500">Non-Veg</label>
                                                            </div>
                                                        </div>

                                                        <div className="vendoraddpackage-checkbox-group">
                                                            <label className="vendor-common-label text14 font600 mb-2">Meal Required</label>
                                                            {mealRequired.map((option) => (
                                                                <div key={option} display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={option.toLowerCase()}
                                                                        style={{ marginRight: '10px' }}
                                                                        onChange={handleCheckboxChange}
                                                                        checked={checkedItems.includes(option)}
                                                                    />
                                                                    <label className="text14 font500">{option}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="vendoraddpackage-checkbox-group">
                                                            <label className="vendor-common-label text14 font600 mb-2">Destination Category</label>
                                                            {Destcat.map((feature) => (
                                                                <div key={feature._id} className="vendoraddpackage-checkbox">
                                                                    <input checked={destinationList.includes(feature._id)} type="checkbox" id={feature._id} name={feature._id} onChange={handleCheckboxChange2} />
                                                                    <label className="text14 font500" htmlFor={feature._id}>{feature.category_name}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="vendoraddpackage-checkbox-group">
                                                        <div className="vendoraddpackage-input-group2 my-0">
                                                            <p className="mb-2 vendor-common-label text14 font600">Hotel Type</p>
                                                            <div className="vendoraddpackage-checkbox-group d-flex gap-4 me-5 mb-0 flex-wrap ">
                                                                <div className="vendoraddpackage-checkbox">
                                                                    <input type="checkbox" value="5" checked={hotelType.includes("5")} name="5" onChange={handleCheckboxChange3} />
                                                                    <label className="text14 font500">5 Star</label>
                                                                </div>
                                                                <div className="vendoraddpackage-checkbox">
                                                                    <input type="checkbox" name="4" value="4" checked={hotelType.includes("4")} onChange={handleCheckboxChange3} />
                                                                    <label className="text14 font500">4 Star</label>
                                                                </div>
                                                                <div className="vendoraddpackage-checkbox">
                                                                    <input type="checkbox" name="3" value="3" checked={hotelType.includes("3")} onChange={handleCheckboxChange3} />
                                                                    <label className="text14 font500">3 Star</label>
                                                                </div>
                                                                <div className="vendoraddpackage-checkbox">
                                                                    <input type="checkbox" name="2" value="2" checked={hotelType.includes("2")} onChange={handleCheckboxChange3} />
                                                                    <label className="text14 font500">2 Star</label>
                                                                </div>
                                                                <div className="vendoraddpackage-checkbox">
                                                                    <input type="checkbox" name="1" value="1" checked={hotelType.includes("1")} onChange={handleCheckboxChange3} />
                                                                    <label className="text14 font500">1 Star</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="vendoraddpackage-input-group2">
                                                            <p className="mb-1 vendor-common-label text14 font600">Room Sharing</p>
                                                            <select
                                                                name="room_sharing"
                                                                value={formData1.room_sharing}
                                                                onChange={handleOptionChange}
                                                                className="vendor-common-input"
                                                            >
                                                                <option value="">Select Room Sharing</option>
                                                                <option value="single sharing">single sharing</option>
                                                                <option value="double sharing">double sharing</option>
                                                                <option value="triple sharing">triple sharing</option>
                                                            </select>
                                                        </div>
                                                        <div className="d-flex gap-2">
                                                            <div className="vendoraddpackage-input-group2 w-50">
                                                                <p className="mb-1 vendor-common-label text14 font600">Travel By</p>
                                                                <select
                                                                    name="travel_by"
                                                                    value={formData1.travel_by}
                                                                    onChange={handleOptionChange}
                                                                    className="vendor-common-input"
                                                                >
                                                                    <option value="">Select Travel By</option>
                                                                    <option value="Train">Train</option>
                                                                    <option value="Flight">Flight</option>
                                                                    <option value="Car">Car</option>
                                                                    <option value="Bus">Bus</option>
                                                                </select>
                                                            </div>
                                                            <div className="vendoraddpackage-input-group2 w-50">
                                                                <p className="mb-1 vendor-common-label text14 font600">Sightseeing</p>
                                                                <select
                                                                    name="sightseeing"
                                                                    value={formData1.sightseeing}
                                                                    onChange={handleOptionChange}
                                                                    className="vendor-common-input"
                                                                >
                                                                    <option value="">Select Sightseeing</option>
                                                                    <option value="include">Include</option>
                                                                    <option value="exclude">Excldue</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vendoraddpackage-flex">
                                                    <div className="vendoraddpackage-services">
                                                        <div className="vendor-common-label text14 font600 mb-1" style={{ fontWeight: "bold", fontSize: "14px" }}>Include Services</div>
                                                        {includeServices.length !== 0 && <div className="vendoraddpackage-service-list">
                                                            <ul>
                                                                {includeServices.map((e, index) => (
                                                                    <li key={index} className="vendoraddpackage-service-item">
                                                                        <span>{e}</span>
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className='vendoraddpackage-remove-button'
                                                                            onClick={() => handleRemoveService(index)}
                                                                        />
                                                                        {/* <button type="button" onClick={() => handleRemoveService(index)} className="vendoraddpackage-remove-button">Remove</button> */}
                                                                    </li>

                                                                ))}
                                                            </ul>

                                                        </div>}
                                                        <div className="vendoraddpackage-input-wrapper">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Include Services"
                                                                value={newService}
                                                                onChange={(e) => setNewService(e.target.value)}
                                                                className="vendor-common-input"
                                                            />
                                                            <button type="button" onClick={handleAddService} className="vendoraddpackage-add-button">Add</button>
                                                        </div>
                                                    </div>
                                                    <div className="vendoraddpackage-services">
                                                        <div className="mb-1 vendor-common-label text14 font600" style={{ fontWeight: "bold", fontSize: "14px" }}>Exclude Services</div>
                                                        {excludeServices.length !== 0 && <div className="vendoraddpackage-service-list">
                                                            <ul>
                                                                {excludeServices.map((e, index) => (
                                                                    <li key={index} className="vendoraddpackage-service-item">
                                                                        <span>{e}</span>
                                                                        <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveExclude(index)} className="vendoraddpackage-remove-button" />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>}
                                                        <div className="vendoraddpackage-input-wrapper">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Exclude Services"
                                                                value={exclude}
                                                                onChange={(e) => setExclude(e.target.value)}
                                                                className="vendor-common-input"
                                                            />
                                                            <button type="button" onClick={handleAddExclude} className="vendoraddpackage-add-button">Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <p className="mb-1 vendor-common-label text14 font600">Other Details</p>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={formData1.more_details}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setFormData1((prevData) => ({
                                                                ...prevData,
                                                                more_details: data
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                                <div className="vendoraddpackage-flex mt-3">
                                                    <div className="vendoraddpackage-input-group">
                                                        <div className="vendor-common-label text14 font600 mb-1">Status:</div>
                                                        <select
                                                            name="status"
                                                            value={formData1.status}
                                                            onChange={handleOptionChange}
                                                            className="vendor-common-input"
                                                        // style={{ width: '100%', padding: '9px 10px', fontSize: '12px', border: '1px solid rgb(216, 216, 216)', borderRadius: '6px', color: 'rgb(122, 131, 139)' }}
                                                        >
                                                            <option disabled value="">Select</option>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>InActive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="price-range-container p-4 bg-light border rounded-md shadow-sm">
                                                        <div className="d-flex justify-content-end mb-3">
                                                            <button
                                                                className="vendoraddpackage-button"
                                                                onClick={handleOpenInsertModal}
                                                            >
                                                                + Add Price Range
                                                            </button>
                                                        </div>
                                                        <table className="table table-striped table-hover">
                                                            <thead className="table-dark">
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">
                                                                        <p className="vendoraddpackage-label text12 font600 mb-0">Price For Adult</p>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <p className="vendoraddpackage-label text12 font600 mb-0">Price For Children</p>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <p className="vendoraddpackage-label text12 font600 mb-0">Price For Infant</p>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <p className="vendoraddpackage-label text12 font600 mb-0">Start Date</p>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <p className="vendoraddpackage-label text12 font600 mb-0">End Date</p>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <p className="vendoraddpackage-label text12 font600 mb-0">Actions</p>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {priceRanges.length > 0 ? (
                                                                    priceRanges
                                                                        .sort((a, b) => {
                                                                            const startDateA = new Date(a.price_start_date);
                                                                            const startDateB = new Date(b.price_start_date);

                                                                            // If the start dates are the same, sort by end date
                                                                            if (startDateA.getTime() === startDateB.getTime()) {
                                                                                const endDateA = new Date(a.price_end_date);
                                                                                const endDateB = new Date(b.price_end_date);
                                                                                return endDateA - endDateB;
                                                                            }

                                                                            return startDateA - startDateB;
                                                                        })
                                                                        .map((range, index) => (
                                                                            <tr key={index}>
                                                                                <td className="text14">{index + 1}.</td>
                                                                                <td className="text14">{range.price_per_person}</td>
                                                                                <td className="text14">{range.child_price}</td>
                                                                                <td className="text14">{range.infant_price}</td>
                                                                                <td className="text14">{formatDate(range.price_start_date?.slice(0, 10))}</td>
                                                                                <td className="text14">{formatDate(range.price_end_date?.slice(0, 10))}</td>
                                                                                <td>
                                                                                    <Button
                                                                                        variant="danger"
                                                                                        onClick={() => handleDelete(index)}
                                                                                        className="btn btn-sm"
                                                                                    >
                                                                                        Delete
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="7" className="text-center text-muted">
                                                                            No price ranges added yet.
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="vendoraddpackage-submit mt-4">
                                                <button onClick={handleSubmit} className="vendoraddpackage-button">Submit</button>
                                            </div>
                                        </div>
                                    </section>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <section>
                                        <div className='costum_container'>
                                            <div className=''>
                                                <div>
                                                    <Form>
                                                        <Row
                                                            className='itinerary_padding green_border gy-2 gx-5 margin_left_right'
                                                            style={{ backgroundColor: "#ffffff" }}
                                                        >
                                                            <div className='col-12'>
                                                                <div className='row'>
                                                                    <div className='col-12 col-md-6'>
                                                                        <span className='text-20 itinerary_header text22 font700'>
                                                                            Day wise Itinerary plan ({formData1.total_days}D/{formData1.total_nights}N)
                                                                        </span>
                                                                    </div>
                                                                    {it?.length != Number(maxDaysOrNights) && (
                                                                        <div className='col-12 col-md-6 d-flex align-items-center justify-content-end'>
                                                                            <p className='cmnclr cmnp'>
                                                                                Add days
                                                                            </p>
                                                                            <span onClick={handleNavigate}>
                                                                                <a>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faPlus}
                                                                                        className='cmnbkg p-2 ms-2'
                                                                                        style={{ cursor: "pointer" }}
                                                                                    />
                                                                                </a>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='row gx-5 gy-3'>
                                                                    {it &&
                                                                        it?.map((ele) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='col-lg-6 col-12'>
                                                                                        <div
                                                                                            style={{
                                                                                                border: "1px solid grey",
                                                                                                borderRadius: "15px",
                                                                                            }}
                                                                                        >
                                                                                            <div className='d-flex justify-content-between'>
                                                                                                <p
                                                                                                    className="text14 font600 mb-0"
                                                                                                    style={{
                                                                                                        backgroundColor: "#09646d",
                                                                                                        color: "white",
                                                                                                        padding: "5px 10px",
                                                                                                        display: "inline-block",
                                                                                                        borderRadius:
                                                                                                            "15px 0px 15px 0px",
                                                                                                    }}
                                                                                                >
                                                                                                    Day {ele.day}
                                                                                                </p>
                                                                                                <p
                                                                                                    className="text14 font600 mb-0"
                                                                                                    style={{
                                                                                                        backgroundColor: "#09646d",
                                                                                                        color: "white",
                                                                                                        padding: "5px 10px",
                                                                                                        display: "inline-block",
                                                                                                        borderRadius:
                                                                                                            "0px 15px 0px 15px",
                                                                                                        cursor: "pointer"
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        navigate(`/vendor/update-package-itineries/${id}/${ele._id}`)
                                                                                                    }}
                                                                                                >
                                                                                                    EDIT
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='p-3'>
                                                                                                <div>
                                                                                                    <div className='mb-2'>
                                                                                                        <label htmlFor='text' className="text14 font600 mb-1">
                                                                                                            Title
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type='text'
                                                                                                            name=''
                                                                                                            id=''
                                                                                                            value={ele.title}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {ele?.photo &&
                                                                                                        <div className="mb-2">
                                                                                                            <label htmlFor="text" className="text14 font600 mb-1 d-block">
                                                                                                                Photo
                                                                                                            </label>
                                                                                                            <img
                                                                                                                src={ele.photo}
                                                                                                                alt="dayphoto"
                                                                                                                style={{ height: "100px", width: "100px", objectFit: "cover", borderRadius: "8px" }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }

                                                                                                    {hotelType === "Not Required" ? (
                                                                                                        <></>
                                                                                                    ) : (
                                                                                                        <div className='mb-2'>
                                                                                                            <label htmlFor='text' className="text14 font600 mb-1">
                                                                                                                Hotel Name
                                                                                                            </label>
                                                                                                            <input
                                                                                                                type='text'
                                                                                                                name=''
                                                                                                                id=''
                                                                                                                readOnly
                                                                                                                value={ele?.hotel_name}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                    <div className='mb-2'>
                                                                                                        <a className="text14 font600 mb-1" style={{ color: "#000", textDecoration: "none" }}>Description/Activity</a>
                                                                                                        <div
                                                                                                            className="black-p"
                                                                                                            style={{
                                                                                                                textAlign: "justify",
                                                                                                                color: "black",
                                                                                                                border: "1px solid #09646d",
                                                                                                                borderRadius: "5px",
                                                                                                                padding: "8px 20px",
                                                                                                                wordBreak: "break-word",  // Ensures long words break and wrap
                                                                                                                overflowWrap: "break-word" // Ensures long words wrap correctly
                                                                                                            }}
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: ele.activity,
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                </div>
                                                            </div>
                                                            {/* <div className='col-12'>
                                                                <div className='py-2 pt-3 text-end'>
                                                                    <button
                                                                        className='check-availibility-button text-20 '
                                                                        style={{
                                                                            background: "#0000",
                                                                            color: "black",
                                                                            border: "1px solid black",
                                                                        }}
                                                                        onClick={() => setValue(0)}
                                                                    >
                                                                        Back
                                                                    </button>
                                                                    <button
                                                                        className='ms-2 check-availibility-button text-20 border-0 '
                                                                        type='submit'
                                                                        style={{
                                                                            backgroundColor: "#155E75",
                                                                        }}
                                                                        onClick={handleSaveit}
                                                                        disabled={it?.length !== Number(totalItinerary)}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div> */}
                                                        </Row>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </TabPanel>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={openRangeModal} onHide={handleCloseRangeModal}>
                <Modal.Body>
                    <Modal.Title>
                        <h4 className="font700 text20">Add Price</h4>
                    </Modal.Title>
                    <div className="mt-3">
                        <p htmlFor="" className="vendor-common-label text14 font600 mb-1" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Adult</p>
                        <input
                            type="text"
                            placeholder="price"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className="vendor-common-input"
                        />
                    </div>
                    <div className="mt-3">
                        <p htmlFor="" className="vendor-common-label text14 font600 mb-1" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Children</p>
                        <input
                            type="text"
                            placeholder="price"
                            name="price"
                            value={priceOfChild}
                            onChange={(e) => setPriceOfChild(e.target.value)}
                            className="vendor-common-input"
                        />
                    </div>
                    <div className="mt-3">
                        <p htmlFor="" className="vendor-common-label text14 font600 mb-1" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Infant</p>
                        <input
                            type="text"
                            placeholder="price"
                            name="price"
                            value={priceOfInfants}
                            onChange={(e) => setPriceOfInfants(e.target.value)}
                            className="vendor-common-input"
                        />
                    </div>
                    <div className="mt-3">
                        <p htmlFor="" className="vendor-common-label text14 font600 mb-1" style={{ fontWeight: "bold", fontSize: "12px" }}>Select Dates For This Price Aplicable</p>
                        <div className="d-flex justify-content-between gap-2">
                            <input
                                type="date"
                                name="start_date"
                                placeholder="Enter Start Date"
                                value={startDatePrice}
                                onChange={handleStartDatePriceChange}
                                min={formattedTomorrowDate}
                                className="vendoraddpackage-input"
                            />

                            <input
                                type="date"
                                name="end_date"
                                placeholder="Enter End Date"
                                value={endDatePrice}
                                onChange={handleEndDatePriceChange}
                                min={startDatePrice}
                                className="vendor-common-input"
                                disabled={!startDatePrice}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center gap-3 mt-4">
                        <button className="check-availibility-button text-20 border-0 " style={{ backgroundColor: "#6c757d" }} onClick={handleCloseRangeModal}>
                            Close
                        </button>
                        <div className="d-flex align-items-center">
                            <button className="check-availibility-button text-20 border-0" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openDeleteDialogue} onHide={handleCloseDeleteDialogue} centered>
                <Modal.Body className="px-4 py-5">
                    <Modal.Title className="text24 font600 text-center">Are you sure you want to delete?</Modal.Title>
                    <div className="d-flex justify-content-center gap-2 mt-4">
                        <Button variant="secondary" onClick={handleCloseDeleteDialogue}>
                            No
                        </Button>
                        <Button variant="danger" onClick={handleConfirmDelete}>
                            Yes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditNewPackage;
