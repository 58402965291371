import React, { useState } from "react";

function ActivityContentOther({ activity, charCount }) {
    const [showFullContent, setShowFullContent] = useState(false);
    const toggleContent = () => setShowFullContent(!showFullContent);
  
    const truncatedContent = activity.slice(0, charCount);
    const isTruncated = activity.length > charCount;
  
    return (
      <div className="cmnp cmnclr font-weight-bolder">
        <div
          style={{ textAlign: "justify", fontSize: "14px", color: "black" }}
          className="cmnp pt-2 text14"
          dangerouslySetInnerHTML={{
            __html: showFullContent
              ? activity
              : isTruncated
                ? `${truncatedContent}...`
                : truncatedContent,
          }}
        />
        {isTruncated && (
          <div className="text-end">
            <a
              className="text16 font600 text-end"
              onClick={toggleContent}
              style={{
                color: "#09646d",
                textDecoration: "none",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
            >
              {showFullContent ? "Show Less" : "More"}
            </a>
          </div>
        )}
      </div>
    );
  }

  export default ActivityContentOther;