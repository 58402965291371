import { useEffect, useState, useContext } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import Carousel from "react-multi-carousel"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import Data from "./Data"
import { Navigation, Pagination, Scrollbar } from "swiper"
import { useNavigate } from "react-router-dom"
import { categoryName } from "../App"
import { BASE_URL } from "../BASE_URL"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

// Carousel responsive settings
const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
}

const Slider = () => {
  const { setCategoryData } = useContext(categoryName)
  const [test, setTest] = useState([])
  const [destinationData, setDestinationData] = useState([])
  const [mostLovedDestination, setMostLovedDestination] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const fetchData = async () => {
    setLoading(true)
    const data2 = Data.slice(0, 5)
    setTest(data2)
    const res = await fetch(`${BASE_URL}home`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    const data = await res.json()
    setLoading(false)
    setDestinationData(data.data[0].DestinationData)
    setMostLovedDestination(data.data[0].most_lovaed_destionation)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button onClick={onClick} className="looking-custom-arrow looking-left-arrow">
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  };

  // Custom arrow component for the right side
  const CustomRightArrow = ({ onClick }) => {
    return (
      <button onClick={onClick} className="looking-custom-arrow looking-right-arrow">
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: "25px" }}>
        <h3 className='font font700 text24'>Are you looking for?</h3>
      </div>

      {loading && <p>Loading...</p>}

      {!loading && (
        <Carousel
          responsive={{
            desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4.5 },
            tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
            mobile: { breakpoint: { max: 464, min: 0 }, items: 2 }, // Show one full slide on mobile
          }}
          infinite={true}
          draggable={true}
          swipeable={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          containerClass="carousel-container"
        >
          {destinationData.map((ele) => (
            <div key={ele._id}>
              <img
                src={ele.photo}
                alt=""
                className="img-fluid mobile_image_set"
                onClick={() => {
                  navigate(`/destination1/${ele._id}`);
                  setCategoryData(ele.category_name);
                }}
              />
              <p className="name_c text-center">{ele.category_name}</p>
            </div>
          ))}
        </Carousel>
      )}

      <div style={{ margin: "25px 0" }}>
        <h3 className='font pt-sm-5 pt-2 font700 text24'>Most Loved Destination!</h3>
      </div>

      <div className="homepage-slider">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar]}
          navigation
          spaceBetween={20}
          slidesPerView={4}
          breakpoints={{
            250: { width: 250, slidesPerView: 2 },
            400: { width: 400, slidesPerView: 2 },
            576: { width: 576, slidesPerView: 2 },
            768: { width: 768, slidesPerView: 3 },
            992: { width: 992, slidesPerView: 3 },
            1199: { width: 1199, slidesPerView: 4 },
          }}
        >
          {mostLovedDestination.map((ele) => (
            <SwiperSlide key={ele._id}>
              <div onClick={() => navigate(`/destination/${ele._id}`)}>
                <img
                  src={ele.place_to_visits}
                  alt=''
                  className='text-center img_radius most_love'
                  style={{ cursor: "pointer" }}
                />
                <p className='name_ca font600'>{ele.destination_name}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Slider
