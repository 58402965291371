import {
    faArrowLeft,
    faBell,
    faCableCar,
    faEnvelopeOpen,
    faLocationPin,
    faPenToSquare,
    faTag,
    faTrash,
    faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import "../Project/css/index1.css"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import Side_navbar from "./Side_navbar"
import My_pannel from "./My_pannel"
import Header from "../Project/Header"
import Header2 from "./Header2"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import './css/vendorpackages.css'
import ProfileSection from "./SecondNavbar"

function MyPackages(props) {
    const navigate = useNavigate()

    const { id } = useParams();

    const [showPackageData, setShowPackageData] = useState([])
    console.log(showPackageData)

    const showPackages = async () => {
        const token = localStorage.getItem("vendorToken")

        const res = await fetch(`${BASE_URL}package/agency_package_display`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
        const data = await res.json()
        setShowPackageData(data.data)
    }

    useEffect(() => {
        showPackages()
    }, [])

    const [editedata, setEditedData] = useState("")

    const Call = async () => {
        const token = localStorage.getItem("vendorToken")
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const data = await res.json()
        setEditedData(data?.data?.[0])
    }

    useEffect(() => {
        Call()
    }, [])

    const handleNav = () => {
        navigate("/vendor/myprofile")
    }

    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <div>
                    <ProfileSection title="My Package List" />

                    <section className='new-vendor-container' style={{ backgroundColor: "white" }}>
                        <div style={{ display: "flex", justifyContent: "end" }}><button className="check-availibility-button text-20 border-0 mb-4" onClick={() => navigate('/vendor/add-new-package')}>Add Package</button></div>
                        <div className='my-package-grid h-100'>
                            {showPackageData &&
                                [...showPackageData]?.reverse()?.map((ele, i) => {
                                    return (
                                        <>
                                            <div className="cursor-pointer h-100" onClick={() => navigate(`/vendor/displaymypackage/${ele._id}`)}>
                                                <div className="viewpackagecard-card h-100">
                                                    <div className="viewpackagecard-name text-start text18 font700">{ele.name}</div>
                                                    <div className="text15 font500">
                                                        <span className="text15 font600">Destination:</span> {ele.destination[0].destination_name}
                                                    </div>
                                                    <div className="text15 font500 my-2">
                                                        <span className="text15 font600">Travel By:</span> {ele.travel_by}
                                                    </div>
                                                    <div className="text15 font500">
                                                        <span className="text15 font600">Categories:</span> {ele.destination_category_id.map(category => category.category_name).join(', ')}
                                                    </div>
                                                    <div className="text15 font500 my-2">
                                                        <span className="text15 font600">Duration:</span> {ele?.total_days}D/{ele?.total_nights}N
                                                    </div>
                                                    <div className="text15 font500">
                                                        <span className="text15 font600">Price per Person:</span> {ele.price_per_person}
                                                    </div>
                                                    <div className="text15 font500 my-2">
                                                        <span className="text15 font600">Status:</span>
                                                        <span className={`badge ${ele.status ? 'badge-success' : 'badge-danger'}`}>
                                                            {ele.status ? 'Active' : 'Inactive'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )
                                })}
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default MyPackages;
