import React, { useState, useEffect, useContext } from "react"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate, useParams } from "react-router-dom"
import Header from "./Header"
import "./css/Destination.css"
import { dataContext } from "../App"
import { categoryName } from "../App"
import { BASE_URL } from "../BASE_URL"
import { useLocation } from "react-router-dom"
import Footer from "./Footer"

function Destination1(props) {
  const { info, setInfo } = useContext(dataContext)
  const { categoryData } = useContext(categoryName)

  const navigate = useNavigate()
  const { id } = useParams()

  const [show, setShow] = useState(false)

  const [temp, setTemp] = useState([])
  const [destination, setDestination] = useState([])
  const [selectedOption, setSelectedOption] = useState("")

  const [lowValue, setLowValue] = useState(0)
  const [highValue, setHighValue] = useState(0)

  const [lowerDay, setLowerDay] = useState(0)
  const [higherDay, setHigherDay] = useState(0)

  const [selectedMonth, setSelectedMonth] = useState("")

  const handleDestinationClick = (destinationName) => {
    setInfo(destinationName)
  }

  const destinationCategory = async () => {
    const res = await fetch(
      `${BASE_URL}destination/getDestinations?category_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    const data = await res.json()
    setDestination(data.data)
    setTemp(data.data)
  }

  useEffect(() => {
    destinationCategory()
    window.scrollTo(0, 0)
  }, [id])

  // filter start form here

  const [selectedFilters, setSelectedFilters] = useState({
    sortBy: null,
    month: null,
    days: null,
    time: null,
    Eight: null,
    SixEight: null,
    FourSix: null,
    TwentyFour: null,
    TenTwenty: null,
    Ten: null,
    LowHighTo: false,
    SerachInput: null,
  })

  const handleHightolow = (e) => {
    let selectprice = ""
    e.target.checked
      ? (selectprice = [...destination].sort((a, b) => b.Package - a.Package))
      : (selectprice = [...temp])
    setDestination(selectprice)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "HighToLow",
      LowHighTo: true,
    }))
  }

  const handleLowtohigh = (e) => {
    let selectprice = ""
    e.target.checked
      ? (selectprice = [...destination].sort((a, b) => a.Package - b.Package))
      : (selectprice = [...temp])
    setDestination(selectprice)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "LowHighTo",
      LowHighTo: false,
    }))
  }

  const handleTen = () => {
    const filteredData = temp.filter((ele) => {
      const monthsRange = ele.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonth

      const inRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      if ((lowerDay === 0 || higherDay === 0) && selectedMonth === "") {
        return ele.Package < 10000
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth === "") {
        return (
          ele.Package < 10000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay
        )
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth !== "") {
        return (
          ele.Package < 10000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay &&
          inRange
        )
      } else if (selectedMonth !== "" && (lowerDay === 0 || higherDay === 0)) {
        return ele.Package < 10000 && inRange
      } else {
        return ele.Package < 10000
      }
    })
    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )
    setDestination(sortedData)

    setLowValue(0)
    setHighValue(10000)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "Ten",
    }))
  }

  const handleTentwenty = () => {
    const filteredData = temp.filter((ele) => {
      const monthsRange = ele.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonth

      const inRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      if ((lowerDay === 0 || higherDay === 0) && selectedMonth === "") {
        return ele.Package >= 10000 && ele.Package <= 20000
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth === "") {
        return (
          ele.Package >= 10000 &&
          ele.Package <= 20000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay
        )
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth !== "") {
        return (
          ele.Package >= 10000 &&
          ele.Package <= 20000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay &&
          inRange
        )
      } else if (selectedMonth !== "" && (lowerDay === 0 || higherDay === 0)) {
        return ele.Package >= 10000 && ele.Package <= 20000 && inRange
      } else {
        return ele.Package >= 10000 && ele.Package <= 20000
      }
    })
    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )
    setDestination(sortedData)

    setLowValue(10000)
    setHighValue(20000)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "TenTwenty",
    }))
  }

  const handleTwentyfour = () => {
    const filteredData = temp.filter((ele) => {
      const monthsRange = ele.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonth

      const inRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      if ((lowerDay === 0 || higherDay === 0) && selectedMonth === "") {
        return ele.Package >= 20000 && ele.Package <= 40000
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth === "") {
        return (
          ele.Package >= 20000 &&
          ele.Package <= 40000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay
        )
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth !== "") {
        return (
          ele.Package >= 20000 &&
          ele.Package <= 40000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay &&
          inRange
        )
      } else if (selectedMonth !== "" && (lowerDay === 0 || higherDay === 0)) {
        return ele.Package >= 20000 && ele.Package <= 40000 && inRange
      } else {
        return ele.Package >= 20000 && ele.Package <= 40000
      }
    })
    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )
    setDestination(sortedData)

    setLowValue(20000)
    setHighValue(40000)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "TwentyFour",
    }))
  }

  const handleFoursix = () => {
    const filteredData = temp.filter((ele) => {
      const monthsRange = ele.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonth

      const inRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      if ((lowerDay === 0 || higherDay === 0) && selectedMonth === "") {
        return ele.Package >= 40000 && ele.Package <= 60000
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth === "") {
        return (
          ele.Package >= 40000 &&
          ele.Package <= 60000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay
        )
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth !== "") {
        return (
          ele.Package >= 40000 &&
          ele.Package <= 60000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay &&
          inRange
        )
      } else if (selectedMonth !== "" && (lowerDay === 0 || higherDay === 0)) {
        return ele.Package >= 40000 && ele.Package <= 60000 && inRange
      } else {
        return ele.Package >= 40000 && ele.Package <= 60000
      }
    })

    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )
    setDestination(sortedData)

    setLowValue(40000)
    setHighValue(60000)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "FourSix",
    }))
  }

  const handleSixeight = () => {
    const filteredData = temp.filter((ele) => {
      const monthsRange = ele.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonth

      const inRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      if ((lowerDay === 0 || higherDay === 0) && selectedMonth === "") {
        return ele.Package >= 60000 && ele.Package <= 80000
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth === "") {
        return (
          ele.Package >= 60000 &&
          ele.Package <= 80000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay
        )
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth !== "") {
        return (
          ele.Package >= 60000 &&
          ele.Package <= 80000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay &&
          inRange
        )
      } else if (selectedMonth !== "" && (lowerDay === 0 || higherDay === 0)) {
        return ele.Package >= 60000 && ele.Package <= 80000 && inRange
      } else {
        return ele.Package >= 60000 && ele.Package <= 80000
      }
    })
    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )
    setDestination(sortedData)

    setLowValue(60000)
    setHighValue(80000)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "SixEight",
    }))
  }

  const handleEight = () => {
    const filteredData = temp.filter((ele) => {
      const monthsRange = ele.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonth

      const inRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      if ((lowerDay === 0 || higherDay === 0) && selectedMonth === "") {
        return ele.Package > 80000
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth === "") {
        return (
          ele.Package > 80000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay
        )
      } else if ((lowerDay !== 0 || higherDay !== 0) && selectedMonth !== "") {
        return (
          ele.Package > 80000 &&
          ele.total_days >= lowerDay &&
          ele.total_days <= higherDay &&
          inRange
        )
      } else if (selectedMonth !== "" && (lowerDay === 0 || higherDay === 0)) {
        return ele.Package > 80000 && inRange
      } else {
        return ele.Package > 80000
      }
    })
    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )
    setDestination(sortedData)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "Eight",
    }))
  }

  const [searchInput, setSearchInput] = useState("")

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value)

    if (event.target.value === "") {
      setDestination(temp)
    } else {
      const search = temp.filter((ele) =>
        ele.destination_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()),
      )

      setDestination(search)

      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: "SerachInput",
      }))
    }
  }

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value
    setSelectedOption(selectedValue)

    const filteredData = temp.filter((item) => {
      const monthsRange = item.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonth

      const inRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      const totalDays = item.total_days

      const [lowerLimit, upperLimit] = selectedValue.split("-").map(Number)
      setHigherDay(upperLimit)
      setLowerDay(lowerLimit)

      if (
        selectedValue === "" &&
        lowValue === 0 &&
        highValue === 0 &&
        selectedMonth !== ""
      ) {
        return inRange
      } else if (
        selectedValue === "" &&
        (lowValue !== 0 || highValue !== 0) &&
        selectedMonth !== ""
      ) {
        return item.Package >= lowValue && item.Package <= highValue && inRange
      } else if (
        selectedValue === "" &&
        (lowValue !== 0 || highValue !== 0) &&
        selectedMonth === ""
      ) {
        return item.Package >= lowValue && item.Package <= highValue
      } else if ((lowValue !== 0 || highValue !== 0) && selectedMonth === "") {
        return (
          totalDays >= lowerLimit &&
          totalDays <= upperLimit &&
          item.Package >= lowValue &&
          item.Package <= highValue
        )
      } else if (selectedMonth !== "" && (lowValue !== 0 || highValue !== 0)) {
        return (
          totalDays >= lowerLimit &&
          totalDays <= upperLimit &&
          item.Package >= lowValue &&
          item.Package <= highValue &&
          inRange
        )
      } else if (selectedMonth !== "" && (lowValue === 0 || highValue === 0)) {
        return totalDays >= lowerLimit && totalDays <= upperLimit && inRange
      } else if (
        selectedValue === "" &&
        (lowValue === 0 || highValue === 0) &&
        selectedMonth === ""
      ) {
        return true
      } else {
        return totalDays >= lowerLimit && totalDays <= upperLimit
      }
    })

    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )
    setDestination(sortedData)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "days",
    }))
  }

  const handleMonthChange = (e) => {
    const selectedMonthValue = e.target.value
    setSelectedMonth(selectedMonthValue)

    const filteredData = temp.filter((item) => {
      const monthsRange = item.best_time_for_visit.split(" - ")
      const startMonth = monthsRange[0].split(" ")[0]
      const endMonth = monthsRange[1]?.split(" ")[0]

      const selectedMonthLowercase = selectedMonthValue

      const isInRange =
        (startMonth <= endMonth &&
          startMonth <= selectedMonthLowercase &&
          selectedMonthLowercase <= endMonth) ||
        (startMonth > endMonth &&
          (selectedMonthLowercase >= startMonth ||
            selectedMonthLowercase <= endMonth))

      const price = item.Package
      const day = item.total_days

      if (
        (lowValue !== 0 || highValue !== 0) &&
        (lowerDay === 0 || higherDay === 0) &&
        selectedMonthValue !== ""
      ) {
        return price >= lowValue && price <= highValue && isInRange
      } else if (
        (lowValue !== 0 || highValue !== 0) &&
        (lowerDay === 0 || higherDay === 0) &&
        selectedMonthValue === ""
      ) {
        return price >= lowValue && price <= highValue
      } else if (
        (lowValue !== 0 || highValue !== 0) &&
        (lowerDay !== 0 || higherDay !== 0) &&
        selectedMonthValue !== ""
      ) {
        return (
          price >= lowValue &&
          price <= highValue &&
          day >= lowerDay &&
          day <= higherDay &&
          isInRange
        )
      } else if (
        (lowValue === 0 || highValue === 0) &&
        (lowerDay !== 0 || higherDay !== 0) &&
        selectedMonthValue !== ""
      ) {
        return day >= lowerDay && day <= higherDay && isInRange
      } else if (
        (lowValue === 0 || highValue === 0) &&
        (lowerDay !== 0 || higherDay !== 0) &&
        selectedMonthValue === ""
      ) {
        return day >= lowerDay && day <= higherDay
      } else if (selectedMonthValue === "") {
        return true
      } else {
        return isInRange
      }
    })

    const sortedData = [...filteredData].sort((a, b) =>
      selectedFilters.LowHighTo ? b.Package - a.Package : a.Package - b.Package,
    )

    setDestination(sortedData)

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: "month",
    }))
  }

  const handleResetFilters = () => {
    // Reset all filters
    setSelectedFilters({
      sortBy: null,
      month: null,
      days: null,
      time: null,
      Eight: null,
      SixEight: null,
      FourSix: null,
      TwentyFour: null,
      TenTwenty: null,
      Ten: null,
      LowHighTo: null,
      SerachInput: null,

      // Add more filter categories as needed
    })

    const inputElements = document.querySelectorAll(
      'input[type="radio"], input[type="checkbox"]',
    )
    inputElements.forEach((input) => {
      input.checked = false
    })

    setSelectedOption("")
    setSelectedMonth("")
    setSearchInput("")
    setLowValue(0)
    setHighValue(0)

    setHigherDay(0)
    setLowerDay(0)

    setDestination(temp)
  }

  return (
    <div className=''>
      <Header setShow={setShow} show={show} />
      <section className='pt-2 pb-5 border-bottom'>
        <div class='container-customes'>
          <div class='d-flex justify-content-between align-items-center destination_filter'>
            <div className='py-3 d-flex justify-content-center destination_title w-100 '>
              <h1
                className='text-center text28 font800'
                style={{ color: "#09646D" }}
              >
                Best {categoryData} Places (India)
              </h1>
            </div>

            <button
              class='bt-filter d-xl-none d-lg-block d-md-block d-sm-block'
              type='button'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasExample'
              aria-controls='offcanvasExample'
            >
              filters
            </button>
          </div>

          {/* <!------------------------------------- filter -------------------------------------------> */}
          <div class='row justify-content-between destination_places'>
            <div
              class='col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-xl-block d-lg-none d-md-none d-sm-none d-none  text-start'
              style={{
                position: "sticky",
                top: "0px",
              }}
            >
              <div class='sort-list filter px-3 py-3'>
                <div>
                  <ul class='list-none ps-0'>
                    <li className='d-flex justify-content-between'>
                      <h6 class='filter-headers text16 font600'>Sort by</h6>
                      <div className='reset-filter-button'>
                        <button onClick={handleResetFilters}>RESET</button>
                      </div>
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='range'
                        className='input-margin'
                        onChange={handleHightolow}
                      />
                      High To Low
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='range'
                        class='input-margin'
                        onChange={handleLowtohigh}
                      />
                      Low To High
                    </li>
                    {/* <li>
                      <input type='radio' name='range' class='input-margin' />
                      Popularity
                    </li> */}
                  </ul>
                </div>
                <div className='mb-3 '>
                  <label htmlFor='' className='filter-headers mb-1 text16 font600'>
                    Search destination
                  </label>
                  <input
                    type='text'
                    placeholder=''
                    className='px-2 py-1 focus-outline'
                    style={{
                      borderRadius: "10px",
                      border: "1px solid lightgrey",
                    }}
                    value={searchInput}
                    onChange={handleSearchInput}
                  />
                </div>
                {/* <div>
                  <ul class='list-none ps-0'>
                    <li>
                      <h6 class='filter-headers'>Type of Destination</h6>
                    </li>
                    <li>
                      <input type='radio' name='nation' class='input-margin' />
                      India
                    </li>
                    <li>
                      <input type='radio' name='nation' class='input-margin' />
                      International
                    </li>
                  </ul>
                </div> */}

                <div class='mb-3'>
                  <h6 class='filters-inner text16 font600'>Duration (in Days)</h6>
                  <select
                    className='select-day'
                    name=''
                    id=''
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value=''>Select a day</option>
                    <option value='1-3'>1-3</option>
                    <option value='4-7'>4-7</option>
                    <option value='8-12'>8-12</option>
                    <option value='12+'>12 or more</option>
                  </select>
                </div>

                <div>
                  <ul class='list-none ps-0'>
                    <li>
                      <h6 class='filters-inner text16 font600'>Budget Per Person</h6>
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='price'
                        class='input-margin'
                        onChange={handleTen}
                      />
                      Less than 10,000
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='price'
                        class='input-margin'
                        onChange={handleTentwenty}
                      />
                      10,000 to 20,000
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='price'
                        class='input-margin'
                        onChange={handleTwentyfour}
                      />
                      20,000 to 40,000
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='price'
                        class='input-margin'
                        onChange={handleFoursix}
                      />
                      40,000 to 60,000
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='price'
                        class='input-margin'
                        onChange={handleSixeight}
                      />
                      60,000 to 80,000
                    </li>
                    <li className="text14">
                      <input
                        type='radio'
                        name='price'
                        class='input-margin'
                        onChange={handleEight}
                      />
                      Above 80,000
                    </li>
                  </ul>
                </div>

                <div class='mb-3'>
                  <h6 class='filters-inner text16 font600'>Select Month</h6>

                  <select
                    className='select-day'
                    name='month'
                    id='month'
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  >
                    <option value=''>Select a month</option>
                    <option value='Jan'>January</option>
                    <option value='Feb'>February</option>
                    <option value='Mar'>March</option>
                    <option value='Apr'>April</option>
                    <option value='May'>May</option>
                    <option value='Jun'>June</option>
                    <option value='Jul'>July</option>
                    <option value='Aug'>August</option>
                    <option value='Sep'>September</option>
                    <option value='Oct'>October</option>
                    <option value='Nov'>November</option>
                    <option value='Dec'>December</option>
                  </select>
                </div>
              </div>
            </div>

            {/* <!------------------------------------- toggle button ------------------------------------> */}

            <div
              class='offcanvas offcanvas-start'
              tabindex='-1'
              id='offcanvasExample'
              aria-labelledby='offcanvasExampleLabel'
            >
              <div class='offcanvas-header'>
                <h5 class='offcanvas-title' id='offcanvasExampleLabel'>
                  Filter Options
                </h5>
                <button
                  type='button'
                  class='btn-close text-reset'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                ></button>
              </div>
              <div class='offcanvas-body'>
                <div class='col-12  text-start'>
                  <div class='sort-list filter px-3 py-3'>
                    <div>
                      <ul class='list-none ps-0'>
                        <li className='d-flex justify-content-between'>
                          <h6 class='filter-headers'>Sort by</h6>
                          <div className='reset-filter-button'>
                            <button onClick={handleResetFilters}>RESET</button>
                          </div>
                        </li>
                        <li>
                          {/* <input type="checkbox" class="input-margin" name="hightolow" value={highToLow} onClick={handleHightolow} />
                      <label htmlFor="hightolow">High To Low</label> */}
                          <input
                            type='radio'
                            name='range'
                            className='input-margin'
                            onChange={handleHightolow}
                          />
                          High To Low
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='range'
                            class='input-margin'
                            onChange={handleLowtohigh}
                          />
                          Low To High
                        </li>
                        {/* <li>
                          <input
                            type='radio'
                            name='range'
                            class='input-margin'
                          />
                          Popularity
                        </li> */}
                      </ul>
                    </div>
                    <div></div>
                    <div className='mb-3 '>
                      <label htmlFor='' className='filter-headers mb-1'>
                        Search destination
                      </label>
                      <input
                        type='text'
                        placeholder=''
                        className='px-2 py-1 focus-outline'
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                        value={searchInput}
                        onChange={handleSearchInput}
                      />
                    </div>
                    <div>
                      <ul class='list-none ps-0'>
                        <li>
                          <h6 class='filter-headers'>Type of Destination</h6>
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                          />
                          India
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                          />
                          International
                        </li>
                      </ul>
                    </div>
                    <div class='mb-3'>
                      <h6 class='filters-inner'>Duration (in Days)</h6>
                      <select
                        className='select-day'
                        name=''
                        id=''
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <option value=''>Select a day</option>
                        <option value='1-3'>1-3</option>
                        <option value='4-7'>4-7</option>
                        <option value='8-12'>8-12</option>
                        <option value='12+'>12 or more</option>
                      </select>
                    </div>
                    <div>
                      <ul class='list-none ps-0'>
                        <li>
                          <h6 class='filters-inner'>Budget Per Person</h6>
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                            onChange={handleTen}
                          />
                          Less than 10,000
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                            onChange={handleTentwenty}
                          />
                          10,000 to 20,000
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                            onChange={handleTwentyfour}
                          />
                          20,000 to 40,000
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                            onChange={handleFoursix}
                          />
                          40,000 to 60,000
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                            onChange={handleSixeight}
                          />
                          60,000 to 80,000
                        </li>
                        <li>
                          <input
                            type='radio'
                            name='nation'
                            class='input-margin'
                            onChange={handleEight}
                          />
                          Above 80,000
                        </li>
                      </ul>
                    </div>

                    <div class='mb-3'>
                      <h6 class='filters-inner'>Select Month</h6>
                      <select
                        className='select-day'
                        name='month'
                        id='month'
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        <option value=''>Select a month</option>
                        <option value='Jan'>January</option>
                        <option value='Feb'>February</option>
                        <option value='Mar'>March</option>
                        <option value='Apr'>April</option>
                        <option value='May'>May</option>
                        <option value='Jun'>June</option>
                        <option value='Jul'>July</option>
                        <option value='Aug'>August</option>
                        <option value='Sep'>September</option>
                        <option value='Oct'>October</option>
                        <option value='Nov'>November</option>
                        <option value='Dec'>December</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!------------------------------------- tourplaces ---------------------------------> */}

            <div class='ps-0 col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div class='tourplaces px-3 py-2'>
                <div class=''>
                  <div className='row gy-2'>
                    {destination &&
                      destination.map((ele) => {
                        return (
                          <>
                            <div
                              className='col-sm-6 col-lg-4 col-12 d-flex justify-content-center px-1'
                              key={ele._id}
                            >
                              <div
                                class='best_natural_places w-100'
                                onClick={() => {
                                  navigate(`/destination/${ele._id}`, {
                                    state: { city: info },
                                  })
                                  handleDestinationClick(ele.destination_name)
                                }}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div class='rating-top'>
                                  <div class='Rating w-100'>
                                    <img
                                      src={ele.Place_to_visit}
                                      alt=''
                                      class='img-fluid w-100'
                                      style={{
                                        height: "200px",
                                        borderRadius: "10px 10px 0px 0px",
                                      }}
                                    />
                                    <span>
                                      <FontAwesomeIcon
                                        icon={faStar}
                                        style={{ color: "yellow" }}
                                      />{" "}
                                      4.3
                                    </span>
                                  </div>
                                  <div class='customise customise2 d-flex justify-content-between'>
                                    <h5 className="text18 font700">{ele.destination_name}</h5>
                                    <div
                                      style={{ width: "44%", textAlign: "end" }}
                                    >
                                      <h4
                                        className='d-inline'
                                        style={{ fontSize: "12px" }}
                                      >
                                        <i class='fa-solid fa-indian-rupee-sign'></i>{" "}
                                        Starting From
                                      </h4>
                                      <h4 className="font700">₹{ele.Package}</h4>
                                    </div>
                                    <div className='customise_span'>
                                      <span>per person</span>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <h6 className='ps-3 text15 font600'>
                                    Best Time To Visit
                                    <span
                                      className='ms-2'
                                      style={{
                                        color: "#09646d",
                                        lineHeight: "15px",
                                      }}
                                    >
                                      {ele.best_time_for_visit}
                                    </span>
                                  </h6>
                                </div>
                                <div class='Customize-btn Customize-btn-2'>
                                  <button className="check-availibility-button text-20 border-0 w-100 text14 font700">More Details</button>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Destination1
